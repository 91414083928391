import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Autocomplete, Box, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import HorizontalStepper from '../../components/atoms/HorizontalStepper/HorizontalStepper';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { newListingSteps } from '../../constants/listings';
import { useFetchListing } from '../../hooks/fetchListing';
import type { ListingDetailType } from '../../types/listing';
import HorizontalStepperActions from '../../components/atoms/HorizontalStepperActions/HorizontalStepperActions';
import { STATE_DROPDOWN_OPTIONS, TIME_ZONES_DROPDOWN_OPTIONS } from '../../constants/settings';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import axios from 'axios';
import { ROUTER_URLS } from '../../constants/router-urls';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import GooglePlacesAutocomplete from '../../components/atoms/GooglePlacesAutocomplete/GooglePlacesAutocomplete';
import type { SelectChangeEvent } from '@mui/material';
import type { SettingTimeZoneType } from '../../types/setting';
import type { PlaceType } from '../../components/atoms/GooglePlacesAutocomplete/GooglePlacesAutocomplete';
import { listingStepTwoValid } from '../../utils/listing';

interface IProps extends WrappedProps {};

interface SelectOption {
  id: string;
  label: string;
};

const InlineLoading = (
  <Grid item lg={10} xs={12}> 
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
      <CircularProgress />
    </Box>
  </Grid>
);

const findAddressElementFromGooglePlace = (element: string, placeDetail: google.maps.GeocoderAddressComponent[]): string | undefined => {
  const placeAttribute = placeDetail.find((place) => place.types.includes(element));
  if (placeAttribute) return placeAttribute.long_name;
  return undefined; 
}

function EditListingAddress({ getConfig, postConfig, getCurrentUserId }: IProps) {
  const { id: listingId } = useParams();
  const navigate = useNavigate();

  const activeStep = 1;
  // const [addressTypeValue, setAddressTypeValue] = React.useState('address');
  const [addressOneValue, setAddressOneValue] = useState('');
  const [addressTwoValue, setAddressTwoValue] = useState('');
  const [addressCity, setAddressCity] = useState('');
  const [addressZip, setAddressZip] = useState('');
  const [addressLatitude, setAddressLatitude] = useState('');
  const [addressLongitude, setAddressLongitdue] = useState('');
  const [addressState, setAddressState] = React.useState<SelectOption | null>(null);
  const [inputAddressState, setInputAddressState] = React.useState('');

  const [googleAddressValue, setGoogleAddressValue] = React.useState<PlaceType | null>(null);

  const [timeZone, setTimeZone] = useState(TIME_ZONES_DROPDOWN_OPTIONS[0].id);

  const [isSaving, setIsSaving] = useState(false);

  const [loadingAddressFromApi, setLoadingAddressFromApi] = useState(false);

  const { data: listingRecord, isLoading } = useFetchListing(getConfig, listingId ?? '');

  useEffect(() => {
    if (listingRecord) {
      // if (getCurrentUserId()?.toString() !== listingRecord.host.id.toString()) {
      //   const path = generatePath(ROUTER_URLS.listings.show, { id: listingId });
      //   navigate(path, { state: { policyError: true } });
      // }
    }

    if (listingRecord?.address) {
      
      setAddressOneValue(listingRecord.address.line_one);
      setAddressTwoValue(listingRecord.address.line_two);
      setAddressCity(listingRecord.address.city);
      setAddressZip(listingRecord.address.zip_code);
      setAddressLatitude(listingRecord.address.latitude?.toString() ?? '');
      setAddressLongitdue(listingRecord.address.longitude?.toString() ?? '');
      const state = listingRecord.address.state;
      if (state && state.length > 0) setAddressState({ id: state, label: STATE_DROPDOWN_OPTIONS.find((option) => option.id === state)?.label ?? '' });
      setTimeZone(listingRecord.timeZone);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [listingRecord])

  // const handleAddressTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setAddressTypeValue((event.target as HTMLInputElement).value);
  // };

  const handleBack = () => {
    const backUrl = generatePath(ROUTER_URLS.listings.edit, { id: listingId });
    navigate({
      pathname: backUrl,
    });
  };

  const handleNext = () => {
    const nextUrl = generatePath(ROUTER_URLS.listings.editAdditionalDetails, { id: listingId });
    navigate({
      pathname: nextUrl,
    });
  };

  const handleTimeZoneChange = (event: SelectChangeEvent) => {
    setTimeZone(event.target.value);
  };

  const handleSave = (moveToNextStep: boolean) => {
    setIsSaving(true);
    const params = {
      listing: {
        address_attributes: {
          id: listingRecord?.address?.id,
          line_one: addressOneValue,
          line_two: addressTwoValue,
          city: addressCity,
          state: addressState?.id,
          zip_code: addressZip,
          longitude: addressLongitude,
          latitude: addressLatitude,
        },
        time_zone: timeZone,
        published: true,
      }
    };

    axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${listingId ?? ''}`, params, postConfig).then((response) => {
      const newListingData: ListingDetailType = response.data;
      if (newListingData.id) {
        if (moveToNextStep) handleNext();
        setIsSaving(false);
      } else {
        console.log('error failed to save');
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const parseAddressFromGooglePlace = (newValue: google.maps.GeocoderAddressComponent[]) => {
    const streetNumber = findAddressElementFromGooglePlace('street_number', newValue);
    const streetAddressOne = findAddressElementFromGooglePlace('route', newValue);
    const addressTwo = findAddressElementFromGooglePlace('subpremise', newValue);
    const city = findAddressElementFromGooglePlace('locality', newValue);
    const state = findAddressElementFromGooglePlace('administrative_area_level_1', newValue);
    const zip = findAddressElementFromGooglePlace('postal_code', newValue);
    
    if (streetNumber && streetAddressOne) {
      setAddressOneValue(`${streetNumber} ${streetAddressOne}`);
    }
    if (addressTwo) {
      setAddressTwoValue(addressTwo);
    } else {
      setAddressTwoValue('');
    }
    if (city) setAddressCity(city);
    if (state) {
      const option = STATE_DROPDOWN_OPTIONS.find((option) => option.label === state);
      if (option) setAddressState(option);
    }
    if (zip) setAddressZip(zip);
  };

  const geocodeByPlaceId = (placeId: string) => {
    setLoadingAddressFromApi(true);
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ placeId }).then((result) => {
      const address = result.results[0];
      parseAddressFromGooglePlace(address.address_components);

      const lat = address.geometry.location.lat();
      const lng = address.geometry.location.lng();
      setAddressLatitude(lat.toString());
      setAddressLongitdue(lng.toString());
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      setLoadingAddressFromApi(false);
    })
  };

  // @todo: make this look nice with a link back to the listings page.
  // this should be returned if the listing id is null empty or undefined as well
  if (!listingRecord) {
    return <DashboardLayout loading />;
  }

  const AddressPanel = (
    <>
      <Grid item>
        <Grid container direction='row'>
          <Grid item lg={10} xs={12}>
            <TextField
              id="outlined-required"
              label="Address 1"
              placeholder="Street Address"
              fullWidth
              value={addressOneValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressOneValue(event.target.value);
              }}
            />
          </Grid>
        </Grid>  
      </Grid>
      <Grid item>
        <Grid container direction='row'>
          <Grid item lg={10} xs={12}>
            <TextField
              id="outlined-required"
              label="Address 2"
              value={addressTwoValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressTwoValue(event.target.value);
              }}
              placeholder="Apt, Suite..."
              helperText="Optional Apartment or Suite number etc."
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction='row' spacing={2}>
          <Grid item lg={4} xs={12}>
            <TextField
              id="outlined-required"
              label="City"
              value={addressCity}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressCity(event.target.value);
              }}
              placeholder="City"
              fullWidth
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <Autocomplete
              value={addressState}
              onChange={(event: any, newValue: SelectOption | null) => {
                if (newValue?.id) setAddressState(newValue);
              }}
              inputValue={inputAddressState}
              onInputChange={(event, newInputValue) => {
                setInputAddressState(newInputValue);
              }}
              id="controllable-states-demo"
              options={STATE_DROPDOWN_OPTIONS}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              // sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="State" />}
            />
          </Grid>          

          <Grid item lg={3} xs={12}>
            <TextField
              id="outlined-required"
              label="Zip"
              value={addressZip}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressZip(event.target.value);
              }}
              placeholder="Zip"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction='row' spacing={2}>
          <Grid item lg={5} xs={12}>
            <TextField
              required
              id="outlined-required"
              label="Latitude"
              value={addressLatitude}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressLatitude(event.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item lg={5} xs={12}>
            <TextField
              required
              id="outlined-required"
              label="Longitude"
              value={addressLongitude}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressLongitdue(event.target.value);
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Grid container direction='column'>
        <Grid item marginTop={5} marginBottom={10}>
          <HorizontalStepper
            activeStep={activeStep}
            steps={newListingSteps}
            listingId={listingId}
          />
          <Grid container direction='column' spacing={2} padding={2}>
            <Grid item>
              <Grid container direction='row'>
                <Grid item lg={10} xs={12}>
                  <GooglePlacesAutocomplete
                    value={googleAddressValue}
                    setValue={setGoogleAddressValue}
                    placeholder="Search for package address"
                    helperText="Please select the nearest point of interest. The general location of the package will work. The package's exact address will only be shared with confirmed hunters."
                    geoCodeCallback={geocodeByPlaceId}
                  />
                </Grid>
              </Grid>
            </Grid>
            { loadingAddressFromApi ? InlineLoading : AddressPanel  }
            <Grid item>
              <Grid container direction='row'>
                <Grid item lg={10} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-helper-label">Time Zone</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={timeZone}
                      label="Time Zone"
                      onChange={handleTimeZoneChange}
                    >
                      { TIME_ZONES_DROPDOWN_OPTIONS.map((option: SettingTimeZoneType) => (
                        <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>Please select a your time zone for listing related notifications</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction='row' spacing={5} justifyContent='center'>
            <Grid item lg={10} xs={12} marginTop={5}>
              <HorizontalStepperActions
                firstStep={false}
                finalStep={false}
                handleNext={handleSave}
                handleBack={handleBack}
                handleSave={handleSave}
                isValid={listingStepTwoValid(addressLatitude, addressLongitude)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default withCommonTools(EditListingAddress);
