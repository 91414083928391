import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Paper, Select, TextField, Typography } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import HorizontalStepper from '../../components/atoms/HorizontalStepper/HorizontalStepper';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { LISTING_ADDITIONAL_COST_ITEM, LISTING_PRICING_UNIT, LISTING_SECURITY_DEPOSIT_PERCENTAGE, newListingSteps } from '../../constants/listings';
import { useFetchListing } from '../../hooks/fetchListing';
import { removeAtIndex } from '../../utils/arrays';
import type { ListingAdditionalCostItemType, ListingPricingUnitType, ListingSecurityDepositPercentageType, ListingDetailType } from '../../types/listing';
import ListingPricingSummary from '../../components/compounds/ListingPricingSummary/ListingPricingSummary';
import HorizontalStepperActions from '../../components/atoms/HorizontalStepperActions/HorizontalStepperActions';
import { ROUTER_URLS } from '../../constants/router-urls';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import { listingStepFourValid } from '../../utils/listing';
import { useUserCanAccessBookNow } from '../../hooks/userCanAccess';
import { APP_DISPLAY_NAME } from '../../constants/app-name';

interface IProps extends WrappedProps {};

function EditListingPricing({ getConfig, postConfig, getCurrentUserId }: IProps) {
  const { id: listingId } = useParams();
  const navigate = useNavigate();
  const canAccessBookNow = useUserCanAccessBookNow();

  const activeStep = 3;

  const [basePrice, setBasePrice] = useState('');
  const [unit, setUnit] = useState('');
  const [perPerson, setPerPerson] = useState(false);
  const [securityDepositPercentage, setSecurityDepositPercentage] = useState(LISTING_SECURITY_DEPOSIT_PERCENTAGE[2].id);

  const [additionalPricingItems, setAdditionalPricingItems] = useState<ListingAdditionalCostItemType[]>([]);
  const [editingPricingItemIndex, setEditingPricingItemIndex] = useState<number>();
  
  const [additionalPricingItemId, setAdditionalPricingItemId] = useState<string | undefined>();
  const [additionalPricingItemDescription, setAdditionalPricingItemDescription] = useState('');
  const [additionalPricingItemCost, setAdditionalPricingItemCost] = useState('');
  const [additionalPricingItemPerPerson, setAdditionalPricingItemPerPerson] = useState(false);
  const [additionalPricingItemPerDay, setAdditionalPricingItemPerDay] = useState(false);
  const [additionalPricingItemPerNight, setAdditionalPricingItemPerNight] = useState(false);
  const [additionalPricingItemIsOptional, setAdditionalPricingItemIsOptional] = useState(false);
  const [additionalPricingItemLimitAmount, setAdditionalPricingItemLimitAmount] = useState(false);
  const [additionalPricingMinimum, setAdditionalPricingMinimum] = useState('');
  const [additionalPricingMaximum, setAdditionalPricingMaximum] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const [surcharge, setSurcharge] = useState<number>();
  const [usageFee, setUsageFee] = useState<number>();

  const { data: listingRecord, isLoading, refetch } = useFetchListing(getConfig, listingId ?? '');

  useEffect(() => {
    // set state here
    if (listingRecord) {
      // if (getCurrentUserId()?.toString() !== listingRecord.host.id.toString()) {
      //   const path = generatePath(ROUTER_URLS.listings.show, { id: listingId });
      //   navigate(path, { state: { policyError: true } });
      // }

      if (listingRecord.basePrice) {
        const displayPrice = listingRecord.basePrice / 100;
        setBasePrice(displayPrice.toString());
      }
      
      setUnit(listingRecord.listingUnit);
      setPerPerson(listingRecord.pricePerPerson);

      if (listingRecord.additionalCosts && listingRecord.additionalCosts.length > 0) {
        const mappedDisplayCosts = listingRecord.additionalCosts.map((cost) => {
          return {
            ...cost,
            price: cost.price / 100,
          }
        });
        setAdditionalPricingItems(mappedDisplayCosts);
      }
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [listingRecord]);

  useEffect(() => {
    const params = {
      app_fee: {
        source: 'marketplace',
      }
    }
    
    if (canAccessBookNow) {
      params.app_fee.source = 'book_now';
    }

    axios.post(API_V3_ALLIGATOR_URLS.appUsageFees.search, params, getConfig).then((response) => {
      setSurcharge(response.data.surcharge);
      setUsageFee(response.data.usageFee);
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      
    });
  }, [])

  const handleNext = () => {
    const nextUrl = generatePath(ROUTER_URLS.listings.editFileUploads, { id: listingId });
    navigate({
      pathname: nextUrl,
    });
  };

  const handleSave = (moveToNextStep: boolean) => {
    setIsSaving(true);
    const mappedAdditionalCharges = additionalPricingItems.map((charge) => ({
      id: charge.id,
      description: charge.description,
      price: charge.price * 100,
      per_person: charge.perPerson,
      per_day: charge.perDay,
      per_night: charge.perNight,
      is_optional: charge.isOptional,
      can_specify_number: charge.canSpecifyNumber,
      min_value: charge.minValue,
      max_value: charge.maxValue,
    })).filter((charge) => charge.description && charge.description.length > 0);

    const itemsToSave = mappedAdditionalCharges;
    const params = {
      listing: {
        base_price: Number(basePrice) * 100,
        listing_unit: unit,
        price_per_person: perPerson,
        listing_additional_charges_attributes: itemsToSave,
      }
    };

    axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${listingId ?? ''}`, params, postConfig).then((response) => {
      const newListingData: ListingDetailType = response.data;
      if (newListingData.id) {
        if (moveToNextStep) {
          handleNext();
          setIsSaving(false);
        } else {
          refetch().then(() => {
            setIsSaving(false);
          }).catch(() => {
            setIsSaving(false);
          })
        }
      } else {
        console.log('error failed to save');
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleBack = () => {
    const backUrl = generatePath(ROUTER_URLS.listings.editAdditionalDetails, { id: listingId });
    navigate({
      pathname: backUrl,
    });
  };

  const handleUnitChange = (event: SelectChangeEvent) => {
    setUnit(event.target.value);
  };

  const handlePerPersonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPerPerson(event.target.checked);
  };

  const handleSecurityDepositChange = (event: SelectChangeEvent) => {
    setSecurityDepositPercentage(event.target.value);
  };

  const handleAddAdditionalPricingItem = () => {
    const currentPricingItems = [...additionalPricingItems];
    if (editingPricingItemIndex !== undefined && editingPricingItemIndex > -1) {
      const newPricingItem = {
        description: additionalPricingItemDescription,
        price: Number(additionalPricingItemCost),
        perPerson: additionalPricingItemPerPerson,
        perDay: additionalPricingItemPerDay,
        perNight: additionalPricingItemPerNight,
        isOptional: additionalPricingItemIsOptional,
        canSpecifyNumber: additionalPricingItemLimitAmount,
        minValue: Number(additionalPricingMinimum),
        maxValue: Number(additionalPricingMaximum),
      }
      currentPricingItems[editingPricingItemIndex] = {...newPricingItem};
      
      currentPricingItems.push(LISTING_ADDITIONAL_COST_ITEM);
      setAdditionalPricingItems(currentPricingItems);

      resetAdditionalPricingItemFields(LISTING_ADDITIONAL_COST_ITEM);
      const editingIndex = currentPricingItems.length - 1;
      setEditingPricingItemIndex(editingIndex);
    } else {
      currentPricingItems.push(LISTING_ADDITIONAL_COST_ITEM);
      setAdditionalPricingItems(currentPricingItems);
      resetAdditionalPricingItemFields(LISTING_ADDITIONAL_COST_ITEM);
      const editingIndex = currentPricingItems.length - 1;
      setEditingPricingItemIndex(editingIndex);
    }
  };

  const handleRemoveAdditionalPricingItem = (index: number) => {
    setIsSaving(true);
    if (additionalPricingItems[index]?.id) {
      const id = additionalPricingItems[index].id as string;

      const apiRoute = API_V3_ALLIGATOR_URLS.listing_additional_charges.destroy.replace(':listingId', listingId ?? '').replace(':id', id);

      axios.delete(apiRoute, postConfig).then(() => {
        const newPricingItems = removeAtIndex(additionalPricingItems, index);
        setAdditionalPricingItems(newPricingItems);
        setIsSaving(false);
      }).catch((e) => {
        console.log(e);
        setIsSaving(false);
      })
    } else {
      const newPricingItems = removeAtIndex(additionalPricingItems, index);
      setAdditionalPricingItems(newPricingItems);
      setIsSaving(false);
    }
  };

  const resetAdditionalPricingItemFields = (pricingItem: ListingAdditionalCostItemType) => {
    setAdditionalPricingItemId(pricingItem.id);
    setAdditionalPricingItemDescription(pricingItem.description ?? '');
    setAdditionalPricingItemCost(pricingItem.price.toString() ?? '');
    setAdditionalPricingItemPerPerson(pricingItem.perPerson ?? false);
    setAdditionalPricingItemPerDay(pricingItem.perDay ?? false);
    setAdditionalPricingItemPerNight(pricingItem.perNight ?? false);
    setAdditionalPricingItemIsOptional(pricingItem.isOptional ?? false);
    setAdditionalPricingItemLimitAmount(pricingItem.canSpecifyNumber ?? false);
    setAdditionalPricingMinimum(pricingItem.minValue.toString() ?? '');
    setAdditionalPricingMaximum(pricingItem.maxValue.toString() ?? '');
  };

  const handleEditAdditionalPricingItem = (index: number) => {
    const item = additionalPricingItems[index];

    if (editingPricingItemIndex !== undefined && editingPricingItemIndex > -1) {
      const currentPricingItems = [...additionalPricingItems];
      const newPricingItem: ListingAdditionalCostItemType = {
        id: additionalPricingItemId,
        description: additionalPricingItemDescription,
        price: Number(additionalPricingItemCost),
        perPerson: additionalPricingItemPerPerson,
        perDay: additionalPricingItemPerDay,
        perNight: additionalPricingItemPerNight,
        isOptional: additionalPricingItemIsOptional,
        canSpecifyNumber: additionalPricingItemLimitAmount,
        minValue: Number(additionalPricingMinimum),
        maxValue: Number(additionalPricingMaximum),
      }
      currentPricingItems[editingPricingItemIndex] = {...newPricingItem};
      setAdditionalPricingItems(currentPricingItems);

      resetAdditionalPricingItemFields(item);
      setEditingPricingItemIndex(index);

    } else {
      resetAdditionalPricingItemFields(item);
      setEditingPricingItemIndex(index);
    }
  };

  const handleSaveAdditionalPricingItem = (index: number, newEditingPricingIndex?: number) => {
    const currentPricingItems = [...additionalPricingItems];
    const newPricingItem = {
      id: currentPricingItems[index].id,
      description: additionalPricingItemDescription,
      price: Number(additionalPricingItemCost),
      perPerson: additionalPricingItemPerPerson,
      perDay: additionalPricingItemPerDay,
      perNight: additionalPricingItemPerNight,
      isOptional: additionalPricingItemIsOptional,
      canSpecifyNumber: additionalPricingItemLimitAmount,
      minValue: Number(additionalPricingMinimum),
      maxValue: Number(additionalPricingMaximum),
    }
    currentPricingItems[index] = {...newPricingItem};
    setAdditionalPricingItems(currentPricingItems);
    setEditingPricingItemIndex(newEditingPricingIndex);
  };

  // @todo: make this look nice with a link back to the listings page.
  // this should be returned if the listing id is null empty or undefined as well
  if (!listingRecord) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout loading={isLoading || isSaving}>
      {
      // <Stack margin={5} spacing={3}></Stack>
      }
      <Grid container direction='column'>
        <Grid item marginTop={5} marginBottom={10}>
          <HorizontalStepper
            activeStep={activeStep}
            steps={newListingSteps}
            listingId={listingId}
          />
          <Grid container direction='row' spacing={2}>
            <Grid item lg={8}>
              <Grid container direction='column' spacing={2}>
                <Grid item marginTop={2}>
                  <Paper>
                    <Grid container direction='column' spacing={2} padding={2}>
                      <Grid item>
                        <Grid container direction='row' spacing={2}>
                          <Grid item lg={4} xs={12}>
                            <FormControl fullWidth required>
                              <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                label="Price"
                                value={basePrice}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  setBasePrice(event.target.value);
                                }}
                                required
                              />
                            </FormControl>
                          </Grid>
                          <Grid item lg={4} xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-helper-label">Unit</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={unit}
                                label="Unit"
                                onChange={handleUnitChange}
                                
                              >
                                <MenuItem value="">
                                  None
                                </MenuItem>
                                { LISTING_PRICING_UNIT.map((option: ListingPricingUnitType) => (
                                  <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                                ))}
                                
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item lg={4} xs={6}>
                            <FormControlLabel control={<Checkbox
                              checked={perPerson}
                              onChange={handlePerPersonChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />} label="Per Person" />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <Grid container direction='row' spacing={2}>
                          {
                          // <Grid item lg={12}>
                          //   <FormControlLabel control={<Checkbox
                          //     checked={requireSecurityDeposit}
                          //     onChange={handleRequireSecurityDepositChange}
                          //     inputProps={{ 'aria-label': 'controlled' }}
                          //   />} label="Do you require a nonrefundable security deposit?" />
                          // </Grid>
                          }
                          {
                            <Grid item lg={12}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-helper-label">Security Deposit Percentage</InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  value={securityDepositPercentage}
                                  label="Security Deposit Percentage"
                                  onChange={handleSecurityDepositChange}
                                  disabled
                                >
                                  { LISTING_SECURITY_DEPOSIT_PERCENTAGE.map((option: ListingSecurityDepositPercentageType) => (
                                    <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                                  ))}
                                  
                                </Select>
                                <FormHelperText>This amount (minus {APP_DISPLAY_NAME}’s outfitter fee) will be triggered as a pay out to you when the hunt is accepted</FormHelperText>
                              </FormControl>
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item marginTop={4}>
                  <Paper>
                    <Grid container direction='row' display='flex' padding={2} justifyContent='center' justifyItems='center'>
                      <Grid item xs={12} lg={12}>
                      
                        <Typography
                          variant='body1'
                          component='p'
                          // align='center'
                          sx={{
                            mt: 0,
                            mb: 0,
                            display: 'flex',
                            // justifyContent: 'center',
                            // justifyItems: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                            height: '100%',
                            fontWeight: 600,
                            // width: '75%',
                            // alignItems: 'center'
                          }}
                        >
                          Additional Costs
                        </Typography>
            
                      </Grid>
                    </Grid>
                    {
                      additionalPricingItems && additionalPricingItems.length > 0 ?
                      additionalPricingItems.map((additionalPricingItem: ListingAdditionalCostItemType, index: number) => {
                        const editing = index === editingPricingItemIndex;
                        return (
                          <Box key={`${additionalPricingItem.description}-${index}`} sx={{ borderTop: 1, borderColor: 'divider' }}>
                            <Grid container direction='row' marginY={2}>
                              <Grid item xs={12} lg={12}>
                                <Box display='flex' alignContent='center' alignItems='center' justifyContent='space-between' height='100%' marginX={2}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color='error'
                                    onClick={() => { handleRemoveAdditionalPricingItem(index) }}
                                  >
                                    Delete
                                  </Button>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    onClick={() => { handleEditAdditionalPricingItem(index) }}
                                    disabled={editing}
                                  >
                                    Edit
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                            
                            <Grid container direction='row'>
                              <Grid item xs={12} lg={12}>
                                <Grid container direction='row' padding={2} spacing={2}>
                                  <Grid item xs={12} lg={8}>
                                    <TextField
                                      required
                                      id="outlined-required"
                                      value={editing ? additionalPricingItemDescription : additionalPricingItem.description}
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setAdditionalPricingItemDescription(e.target.value)
                                      }}
                                      label="Description"
                                      fullWidth
                                      disabled={!editing}
                                    />
                                  </Grid>
                                  <Grid item xs={12} lg={4}>
                                    <FormControl fullWidth>
                                      <InputLabel htmlFor="outlined-adornment-amount">Cost</InputLabel>
                                      <OutlinedInput
                                        id="outlined-adornment-amount"
                                        value={editing ? additionalPricingItemCost : additionalPricingItem.price.toString()}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          setAdditionalPricingItemCost(e.target.value)
                                        }}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        label="Cost"
                                        disabled={!editing}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <Grid container direction='row' spacing={2} padding={2}>
                                  <Grid item xs={6} lg={3}>
                                    <FormControlLabel control={<Checkbox
                                      checked={editing ? additionalPricingItemPerPerson : additionalPricingItem.perPerson}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setAdditionalPricingItemPerPerson(event.target.checked);
                                      }}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                      disabled={!editing}
                                    />} label="Per Person" />
                                  </Grid>
                                  <Grid item xs={6} lg={3}>
                                    <FormControlLabel control={<Checkbox
                                      checked={editing ? additionalPricingItemPerDay : additionalPricingItem.perDay}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setAdditionalPricingItemPerDay(event.target.checked);
                                      }}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                      disabled={!editing}
                                    />} label="Per Day" />
                                  </Grid>
                                  <Grid item xs={6} lg={3}>
                                    <FormControlLabel control={<Checkbox
                                      checked={editing ? additionalPricingItemPerNight : additionalPricingItem.perNight}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setAdditionalPricingItemPerNight(event.target.checked);
                                      }}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                      disabled={!editing}
                                    />} label="Per Night" />
                                  </Grid>
                                  <Grid item xs={6} lg={3}>
                                    <FormControlLabel control={<Checkbox
                                      checked={editing ? additionalPricingItemIsOptional : additionalPricingItem.isOptional}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setAdditionalPricingItemIsOptional(event.target.checked);
                                      }}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                      disabled={!editing}
                                    />} label="Is Optional" />
                                  </Grid>
                                </Grid>
                        
                                <Grid container direction='row' spacing={2} padding={2}>
                                  <Grid item xs={12} lg={4}>
                                    <FormControlLabel control={<Checkbox
                                      checked={editing ? additionalPricingItemLimitAmount : additionalPricingItem.canSpecifyNumber}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setAdditionalPricingItemLimitAmount(event.target.checked);
                                      }}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                      disabled={!editing}
                                    />} label="Limit Amount" />
                                  </Grid>
                                  <Grid item xs={6} lg={4}>
                                    <TextField
                                      id="outlined-number"
                                      value={editing ? additionalPricingMinimum : additionalPricingItem.minValue.toString()}
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setAdditionalPricingMinimum(e.target.value)
                                      }}
                                      label="Min"
                                      type="number"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={!editing}
                                    />
                                  </Grid>
                                  <Grid item xs={6} lg={4}>
                                    <TextField
                                      id="outlined-number"
                                      value={editing ? additionalPricingMaximum : additionalPricingItem.maxValue.toString()}
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setAdditionalPricingMaximum(e.target.value)
                                      }}
                                      label="Max"
                                      type="number"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={!editing}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            {editing && 
                            <Grid container direction='row' paddingY={2} display='flex' alignContent='center' alignItems='center' justifyContent='center'>
                              <Grid item xs={12} lg={4}>
                                <Box display='flex' alignContent='center' alignItems='center' justifyContent='flex-end' height='100%' marginX={2}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color='secondary'
                                    onClick={() => { handleSaveAdditionalPricingItem(index) }}
                                    sx={{ width: '100%' }}
                                    disabled={!(additionalPricingItemDescription && additionalPricingItemDescription.length > 0)}
                                  >
                                    Finish
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>}
                          </Box>
                        );
                      }) :
                      <Grid container direction='row' display='flex' padding={2} justifyContent='center' justifyItems='center'>
                        <Grid item lg={12}>
                          <Typography
                            variant='body1'
                            component='p'
                            sx={{
                              mt: 0,
                              mb: 0,
                              display: 'flex',
                              justifyContent: 'center',
                              justifyItems: 'center',
                              // height: '100%',
                              fontStyle: 'italic',
                              width: '100%',
                            }}
                          >
                            Package has no additional costs
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                    <Grid container direction='row' display='flex' alignContent='center' alignItems='center' justifyContent='center' sx={{ borderTop: 1, borderColor: 'divider' }}>
                      <Grid item xs={12} lg={4} display='flex' paddingX={2} justifyContent='center' justifyItems='center' alignContent='center'>
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{ marginY: 3, width: '100%' }}
                          color='primary'
                          onClick={handleAddAdditionalPricingItem}
                        >
                          + Additional Cost
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              {surcharge !== undefined && usageFee !== undefined && <ListingPricingSummary listingBasePrice={Number(basePrice)} surcharge={surcharge} usageFee={usageFee} />}
            </Grid>
          </Grid>
          <Grid container direction='row' spacing={5} justifyContent='center'>
            <Grid item lg={10} marginTop={5}>
              <HorizontalStepperActions
                firstStep={false}
                finalStep={false}
                handleNext={handleSave}
                handleBack={handleBack}
                handleSave={handleSave}
                isValid={listingStepFourValid(basePrice)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default withCommonTools(EditListingPricing);
