import React from 'react';
import { Box, CircularProgress, Typography } from "@mui/material";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface IProps {
  isLoading: boolean;
  salesSummaryData?: any;
}

const sampleSalesData = [
  { id: 1, month: 'Aug', amount: 1000 },
  { id: 2, month: 'Sep', amount: 5000 },
  { id: 3, month: 'Oct', amount: 2750 },
  { id: 4, month: 'Nov', amount: 3250 },
  { id: 5, month: 'Dec', amount: 4000 },
  { id: 6, month: 'Jan', amount: 1500 },
];

const formatYAxis = (value: number) => {
  if (value < 100) return '';
  if (value > 999) {
    const divided = value / 1000;
    return `$${(divided.toFixed(1))}k`;
  }
  return `$${value}`;
}

function SalesSummaryChart({ isLoading, salesSummaryData }: IProps) {
  if (isLoading) {
    return (
      <Box sx={{
        mt: 3,
        mb: 3,
        display: 'flex',
        justifyContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        minHeight: '400px',
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!salesSummaryData) {
    salesSummaryData = [0];
  }

  const recentDepositTotal = salesSummaryData.reduce((total: number, summary: any) => {
    if (typeof summary.amount === 'number') {
      return total + Number(summary.amount);
    }
    return total;
  }, 0);

  if (recentDepositTotal < 1) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        paddingY: 5,
        // opacity: .25
        }}
        height={400}
      >
        <Box height={400} width='100%' sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          justifyItems: 'center',
          alignItems: 'center',
          opacity: .33
        }}>
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart
              width={500}
              height={300}
              data={sampleSalesData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey="month" />
              <YAxis tickFormatter={formatYAxis} />
              <Tooltip cursor={false} formatter={(value) => { return `$${value.toLocaleString()}` }} />
              <Bar dataKey="amount" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          justifyItems: 'center',
          alignItems: 'center',
          zIndex: 10,
          position: 'absolute'
          }}
        >
          <Typography textAlign='center' maxWidth='65%' fontWeight={600}>
            You're seeing sample data because you haven't had any hunts sold yet. <br/><br />For help increasing hunts sold, contact customer support today.</Typography>
        </Box>
      </Box>
    )
  }
  
  return (
    <Box height={400} sx={{ paddingY: 5 }}>
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart
          width={500}
          height={300}
          data={salesSummaryData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="month" />
          <YAxis tickFormatter={formatYAxis} />
          <Tooltip cursor={false} formatter={(value) => { return `$${value.toLocaleString()}` }} />
          <Bar dataKey="amount" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default SalesSummaryChart;