import React from 'react';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import { Alert, Box, Button, Grid, Link, Stack } from '@mui/material';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { useFetchListing } from '../../hooks/fetchListing';
import { ROUTER_URLS } from '../../constants/router-urls';
import RecordDetailHeader from '../../components/atoms/RecordDetailHeader/RecordDetailHeader';
import RecordDetailAttribute from '../../components/atoms/RecordDetailAttribute/RecordDetailAttribute';
// import { formatTimeString } from '../../utils/dates';
import RecordDetailRichText from '../../components/atoms/RecordDetailRichText/RecordDetailRichText';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';

interface IProps extends WrappedProps {};

function ShowListing({ getConfig }: IProps) {
  const { id: listingId } = useParams();
  const location = useLocation();
  const { policyError } = location.state ?? { policyError: false };

  const { data: listingRecord } = useFetchListing(getConfig, listingId ?? '');
  // const { data: listingRecord } = useQuery(
  //   ['ListingInfo', listingId],
  //   async () => await fetchById(API_URLS.listing.edit, listingId ?? ""),
  //   {
  //     keepPreviousData: true,
  //     refetchOnWindowFocus: false,
  //   }
  // )

  const createEditPath = (editPath: string) => {
    return generatePath(editPath, { id: listingId });
  };

  // @todo: make this look nice with a link back to the listings page.
  // this should be returned if the listing id is null empty or undefined as well
  if (!listingRecord) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout>
      {policyError && <Alert severity="error" sx={{ marginTop: 2, mx: 5 }}>Unable to edit package. The current user must be the package outfitter to make changes.</Alert>}
      <Stack>
        <RecordDetailHeader
          title="Package Details"
          description=""
          editPath={createEditPath(ROUTER_URLS.listings.edit)}
        >
          {
          listingRecord.slug && (
            <Link target='_blank' rel="noopener" href={`https://www.venku.com/listing/s/${listingRecord.slug}`} underline='none' color='text.primary'>
              <Button variant='contained' color='primary'>
                Preview
              </Button>
            </Link>
          )
          }
        </RecordDetailHeader>
        <Box sx={{ marginX: 5 }}>
          <RecordDetailAttribute attribute="Title" value={listingRecord.name} />
          <RecordDetailAttribute attribute="Category" value={listingRecord.category} />
          <RecordDetailAttribute attribute="Short Description" value={listingRecord.shortDescription} />
          <RecordDetailAttribute attribute="Keywords" value={listingRecord.keywords.join(", ")} />
        </Box>

        <RecordDetailHeader
          title="Address"
          description=""
          editPath={createEditPath(ROUTER_URLS.listings.editAddress)}
        />
        <Box sx={{ marginX: 5 }}>
          <RecordDetailAttribute attribute="Address One" value={listingRecord.address?.line_one} />
          <RecordDetailAttribute attribute="Address Two" value={listingRecord.address?.line_two} />
          <RecordDetailAttribute attribute="City" value={listingRecord.address?.city} />
          <RecordDetailAttribute attribute="State" value={listingRecord.address?.state} />
          <RecordDetailAttribute attribute="Zip" value={listingRecord.address?.zip_code} />
          <RecordDetailAttribute attribute="Country" value={listingRecord.address?.country} />
          <RecordDetailAttribute attribute="Latitude" value={listingRecord.address?.latitude ?? ''} />
          <RecordDetailAttribute attribute="Longitude" value={listingRecord.address?.longitude ?? ''} />
        </Box>

        <RecordDetailHeader
          title="Additional Details"
          description=""
          editPath={createEditPath(ROUTER_URLS.listings.editAdditionalDetails)}
        />
        <Box sx={{ marginX: 5 }}>
          <RecordDetailAttribute attribute="Address One" value={listingRecord.timeZone} />
          <RecordDetailAttribute attribute="Handicap Accessible" value={listingRecord.handicappedAccessible ? 'Y' : 'N' } />
          <RecordDetailAttribute attribute="Kid Friendly" value={listingRecord.kidFriendly ? 'Y' : 'N' } />
          <RecordDetailAttribute attribute="Amenities" value={listingRecord.amenities.join(", ") } />
          <RecordDetailAttribute attribute="Gear Requirements" value={listingRecord.extras.join(", ") } />
          <RecordDetailRichText attribute="House Rules" value={listingRecord.houseRules } />
          <RecordDetailRichText attribute="Description" value={listingRecord.description} />
          <RecordDetailRichText attribute="Reviews" value={listingRecord.testimonials} />
        </Box>

        <RecordDetailHeader
          title="Pricing"
          description=""
          editPath={createEditPath(ROUTER_URLS.listings.editPricing)}
        />

        <RecordDetailHeader
          title="Photos"
          description=""
          editPath={createEditPath(ROUTER_URLS.listings.editFileUploads)}
        />
        <Grid container direction='row' marginX={5} marginTop={1}>
          {
          //   listingRecord.images.map((image) => {
          //   const url = `https://prod-assets.outside360.com/${image.publicUrl ?? ""}`;
          //   return (
          //     <Grid item key={image.id} style={{ padding: 3 }} xs={4} lg={2}>
          //       <img src={url} alt="" style={{ width: '100%', height: "100%", objectFit: 'contain' }} />
          //     </Grid>
          //   )
          //   })
          }
        </Grid>
        <RecordDetailHeader
          title="Additional Documents"
          description=""
          editPath={createEditPath(ROUTER_URLS.listings.editFileUploads)}
        />

        <RecordDetailHeader
          title="Availability"
          description=""
          editPath={createEditPath(ROUTER_URLS.listings.editAvailability)}
        />
        <Box sx={{ marginX: 5 }}>
          
          <RecordDetailAttribute attribute="Limit Number of Hunters" value={listingRecord.enforceAccomodationRange ? 'Y' : 'N' } />
          {
            listingRecord.enforceAccomodationRange && (
              <>
                <RecordDetailAttribute attribute="Minimum Number of Hunters" value={listingRecord.accommodateMin.toString()} />
                <RecordDetailAttribute attribute="Maximum Number of Hunters" value={listingRecord.accommodateMax.toString()} />
              </>
            )
          }
          {
          // <RecordDetailAttribute attribute="Cancellation Allowed" value={listingRecord.cancellationPolicy?.cancellationAllowed ? 'Y' : 'N'} />
          // <RecordDetailAttribute attribute="Cancellation Window" value={listingRecord.cancellationPolicy?.cancellationWindow?.toString() ?? ''} />
          // <RecordDetailAttribute attribute="Cancellation Policy" value={listingRecord.cancellationPolicy?.name ?? ''} />
          // <RecordDetailAttribute attribute="Cancellation Description" value={listingRecord.cancellationPolicy?.shortDescription ?? ''} />
          // <RecordDetailAttribute attribute="Contact Phone Number" value={listingRecord.phone ?? ''} />
          }
        </Box>
      </Stack>
    </DashboardLayout>
  );
}

export default withCommonTools(ShowListing);
