import React, { useState } from "react";
import { Alert, Box, Button, TextField } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import LoadingSpinner from "../../components/atoms/LoadingSpinner/LoadingSpinner";
import axios from "axios";
import { API_V3_ALLIGATOR_URLS } from "../../constants/api-urls";
import { useNavigate } from "react-router-dom";
import { ROUTER_URLS } from "../../constants/router-urls";
import SquareLogo from "../../components/compounds/SquareLogo/SquareLogo";


function ForgotPassword() {
  const navigate = useNavigate();

  const [userName, setUserName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [notificationText, setNotificationText ] = useState('');
  const [errorText, setErrorText ] = useState('');
  
  const handleSubmit = (): void => {
    setErrorText('');
    setNotificationText('');
    setIsSaving(true);

    axios.post(API_V3_ALLIGATOR_URLS.auth.forgotPassword, { user: { email: userName } }).then((res) => {
      if (res.status === 200) {
        setNotificationText("Please check your inbox for a link to reset your password");
        setIsSaving(false);
      } else {
        setErrorText("Unable to send password reset link");
      }
    }).catch(() => {
      setIsSaving(false);
      setErrorText("Unable to send password reset link");
    })
  };

  const submitEnabled = (): boolean => {
    return (Boolean(userName) && userName.length > 4) && !isSaving;
  }

  return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <SquareLogo />
          <Typography component="h1" variant="h5" mt={5}>
            Forgot Password
          </Typography>
          <Box sx={{ my: 1 }}>
            { errorText &&
              <Alert severity="error" sx={{ my: 2 }}>{ errorText }</Alert>
            }
            { notificationText &&
              <Alert severity="success" sx={{ my: 2 }}>{ notificationText }</Alert>
            }
            { isSaving ? <LoadingSpinner /> :
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoFocus
              value={userName}
              onChange={(e) => { setUserName(e.target.value)} }
            />}
            <Button
              type="submit"
              fullWidth
              onClick={handleSubmit}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!submitEnabled()}
            >
              Send Password Reset Link
            </Button>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => { navigate(ROUTER_URLS.home) }}
              sx={{ mt: 1, mb: 2 }}
            >
              Back
            </Button>
          </Box>
        </Box>
      </Container>
  );
};

export default ForgotPassword;
