import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, FormHelperText, Grid, MenuItem, Select, Typography } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { useFetchListings } from '../../hooks/fetchListing';
import type { ListingDetailType } from '../../types/listing';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import { calendarDatesToStringDates, stringDatesToCalendarDates } from '../../utils/arrays';
import BigCalendar from '../../components/compounds/BigCalendar/BigCalendar';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import { fetchById } from '../../api/requests';
import type { EventInput } from '@fullcalendar/core';
import axios from 'axios';
import LoadingSpinner from '../../components/atoms/LoadingSpinner/LoadingSpinner';
import ChangeColorModal from '../../components/compounds/ChangeColorModal/ChangeColorModal';
import { listingDisplayName } from '../../utils/listing';

interface IProps extends WrappedProps {};

function CalendarAvailability({ getConfig, postConfig, getCurrentUserId }: IProps) {

  const [selectedListingId, setSelectedListingId] = useState<string>('');
  const [calendarAvailableDates, setCalendarAvailableDates] = useState<EventInput[]>([]);
  const [listingRecord, setListingRecord] = useState<ListingDetailType>();
  const [hexColor, setHexColor] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [nickname, setNickname] = useState('');
  // const [listingName, setListingName] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [timeStamp, setTimeStamp] = useState(new Date().getTime())

  const { data: listingRecords, isLoading: isListLoading, refetch: reload } = useFetchListings(postConfig);

  useEffect(() => {
    if (selectedListingId) {
      setIsLoading(true)
      fetchById(API_V3_ALLIGATOR_URLS.listing.show, selectedListingId, getConfig).then((res: ListingDetailType) => {
        const mappedDates = stringDatesToCalendarDates(res.availableDates, res.hexColor);
        setListingRecord(res);
        setCalendarAvailableDates(mappedDates)
        setHexColor(res.hexColor);
        setNickname(res.hostAppDisplayName);
        // setListingName(res.name);
        setIsLoading(false)
      }).catch((e) => {
        console.log(e);
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [selectedListingId, timeStamp])

  const handleClose = () => {
    setOpen(false);
    // setSelectedTripId(undefined);
    // setTimeStamp(new Date().getTime());
  };

  const handleSave = () => {
    setIsLoading(true);
    
    const availableDates = calendarDatesToStringDates(calendarAvailableDates);

    const params: { listing: { available_dates: string[]; hex_color?: string; host_app_display_name?: string } } = {
      listing: {
        available_dates: availableDates,
      }
    };
    if (listingRecord?.hexColor !== hexColor) {
      params.listing.hex_color = hexColor;
    }
    if (listingRecord?.hostAppDisplayName !== nickname) {
      params.listing.host_app_display_name = nickname;
    }

    axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${selectedListingId ?? ''}`, params, postConfig).then((response) => {
      const newListingData: ListingDetailType = response.data;
      if (newListingData.id) {
        setIsLoading(false);
        if (open) {
          setTimeStamp(new Date().getTime());
          reload().catch((e) => { console.log(e) });
          handleClose()
        }
      } else {
        console.log('error failed to save');
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleListingSelectChange = (event: SelectChangeEvent) => {
    setSelectedListingId(event.target.value);
  };

  return (
    <DashboardLayout loading={isListLoading}>
      <Grid container marginBottom={10} spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h4' sx={{ textAlign: 'center' }}>Set Open Dates</Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ marginY: 2 }}>
          <FormControl fullWidth>
            <Select
              labelId="listing-label"
              id="listing-select"
              value={selectedListingId}
              onChange={handleListingSelectChange}
              fullWidth
              placeholder='Select Package'
              displayEmpty
            >
              <MenuItem value="">
                Select Package
              </MenuItem>
              {listingRecords && listingRecords.length > 0 && listingRecords.map((listing) => {
                  return (
                    <MenuItem key={listing.id} value={listing.id.toString()}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                        <Box
                          onClick={(e) => {
                            e.stopPropagation();
                            console.log('clicked!')
                          }}
                          sx={{ backgroundColor: listing.hexColor, height: '20px', width: '20px', borderRadius: 1 }}
                        /><Typography>{listingDisplayName(listing)}</Typography>
                      </Box>
                    </MenuItem>
                  )
                })}
            </Select>
            {!selectedListingId && <FormHelperText>Select a package to set your open dates and change the color</FormHelperText>}
          </FormControl>
          {selectedListingId && <Button variant='text' onClick={() => { setOpen(true) }} sx={{ textTransform: 'none' }}>Change color or name</Button>}
        </Grid>
        {isLoading ? 
          <Grid item xs={12}>
            <LoadingSpinner />
          </Grid>
        : <> 
          {selectedListingId ? (
            <>
              <Grid item xs={12}>
                <BigCalendar
                  dates={calendarAvailableDates}
                  setDates={setCalendarAvailableDates}
                  hexColor={hexColor}
                />
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Button variant='contained' color='secondary' sx={{ width: 250, borderRadius: '6px', marginX: 2 }} disabled={isLoading} onClick={handleSave}>Save</Button>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 5 }}>
                <Box sx={{ height: '30px', width: '30px', backgroundColor: hexColor, borderRadius: 1, marginRight: 1 }} />
                <Typography variant='h6' textAlign='center'>Open dates are marked by color</Typography>
              </Grid>
            </>
          ) : 
            <>
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 8 }}>
                <Typography variant='h6' textAlign='center'>Open dates are marked by color</Typography>
              </Grid>
            </>
          }
        </>}
      </Grid>
      <div>
        { selectedListingId && listingRecord &&
          <ChangeColorModal
            listingId={selectedListingId}
            listingName={listingRecord.name}
            listingColor={hexColor}
            setListingColor={setHexColor}
            onClose={handleClose}
            open={open}
            getConfig={getConfig}
            handleSave={handleSave}
            nickname={nickname}
            setNickname={setNickname}
          />
        }
      </div>
    </DashboardLayout>
  );
}

export default withCommonTools(CalendarAvailability);
