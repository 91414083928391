import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LISTING_COLOR_PALLETE } from '../../../constants/listing-colors';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import CheckIcon from '@mui/icons-material/Check';

interface ConfigType {
  headers: {
      Authorization: string;
  };
}

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  getConfig: ConfigType;
  listingId?: string;
  listingName?: string;
  listingColor: string;
  setListingColor: (newValue: string) => void;
  nickname: string;
  setNickname: (newValue: string) => void;
  handleSave: () => void;
}

export default function ChangeColorModal(props: SimpleDialogProps) {
  const { onClose, open, listingName, listingColor, setListingColor, handleSave, nickname, setNickname } = props;

  // const [selectedColor, setSelectedColor] = useState(listingColor)


  const handleClose = () => {
    onClose();
  };

  // const handleSave = () => {
  //   if (listingId) {
  //     axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}/${listingId}`, { listing: { hex_color: selectedColor }}, getConfig).then((res) => {
  //       console.log(res)
  //     }).catch((e) => {
  //       console.log(e)
  //     })
  //   }
  // }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {listingName}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid2 container sx={{ margin: { xs: 0, md: 3 }, marginBottom: 3 }}>
          <Grid2 xs={12}>
            <Typography variant='body1' fontSize={20}>Change display color:</Typography>
          </Grid2>
          {LISTING_COLOR_PALLETE.map((color) => {
            let border = {};
            if (listingColor === color) border = { border: 3, borderColor: '#000000', };
            return (
              <Grid2 key={color} xs={3} sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                <Button onClick={() => { setListingColor(color); }}>
                  <Box sx={{
                      backgroundColor: color,
                      width: '30px',
                      height: '30px',
                      borderRadius: 2,
                      padding: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      ...border,
                    }}
                  >
                    {listingColor === color && <CheckIcon sx={{ color: '#000' }} />}
                  </Box>
                </Button>
              </Grid2>
            )
          })}
          <Grid2 xs={12} marginTop={4}>
            <Typography variant='body1' fontSize={20}>Change package name:</Typography>
          </Grid2>
          <Grid item xs={12} lg={6} marginTop={1}>
            <TextField
              id="outlined-required"
              label="Nickname"
              value={nickname}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNickname(event.target.value);
              }}
              fullWidth
              helperText="Set how you want the title to appear on your calendar"
            />
          </Grid>
        </Grid2>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
