import React from 'react';
import DashboardLayout from '../../../components/layouts/Dashboard/Dashboard';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import { useFetchUserAsAdmin } from '../../../hooks/fetchUser';
import { Avatar, Box, Button, Grid, Stack, Typography } from '@mui/material';
import RecordDetailHeader from '../../../components/atoms/RecordDetailHeader/RecordDetailHeader';
import { ROUTER_URLS } from '../../../constants/router-urls';
import RecordDetailAttribute from '../../../components/atoms/RecordDetailAttribute/RecordDetailAttribute';
import { formatDateString } from '../../../utils/dates';
import { displayPhoneNumber } from '../../../utils/phoneNumber';
import toProperCase from '../../../utils/strings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { APP_DISPLAY_NAME } from '../../../constants/app-name';

interface IProps extends WrappedProps {};

function AdminUser({ getConfig }: IProps) {
  const { id: userId } = useParams();
  const navigate = useNavigate();

  const { data: userRecord, isLoading } = useFetchUserAsAdmin(getConfig, userId ?? '');

  const createEditPath = (editPath: string) => {
    return generatePath(editPath, { id: userId });
  };

  const navigateToPath = (editPath: string) => {
    const path = createEditPath(editPath);
    navigate(path, { state: { userName: userRecord?.fullName } });
  };

  if (!userRecord) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout loading={isLoading}>
      <Stack>
        <RecordDetailHeader
          title="User Details"
          description="General information"
          editPath={createEditPath(ROUTER_URLS.admin.users.edit)}
        >
          <Button variant='contained' color='error' disabled>
            Delete
          </Button>
          <Button variant='contained' color='warning' disabled sx={{
              marginLeft: 2
            }}>
            Block User
          </Button>
        </RecordDetailHeader>
        <Box sx={{ marginX: 5 }}>
          <RecordDetailAttribute attribute="First Name" value={userRecord.firstName} />
          <RecordDetailAttribute attribute="Last Name" value={userRecord.lastName} />
          <RecordDetailAttribute attribute="Email" value={userRecord.email} />
          <RecordDetailAttribute attribute="Date of Birth" value={formatDateString(userRecord.dateOfBirth)} />
          <RecordDetailAttribute attribute="Phone Number" value={displayPhoneNumber(userRecord.phoneNumber) ?? ''} />
          <RecordDetailAttribute attribute="Gender" value={userRecord.gender ?? ''} />
          
          
          
          <RecordDetailAttribute attribute="User Type" value={userRecord.userType && toProperCase(userRecord.userType)} />
          <RecordDetailAttribute attribute="Access Host App" value={userRecord.accessHostApp ? 'T' : 'N'} />
          <RecordDetailAttribute attribute="Access Hunt Pay Tools" value={userRecord.accessBookNowApp ? 'T' : 'N'} />
          <RecordDetailAttribute attribute="Member Since" value={formatDateString(userRecord.memberSince) ?? 'N/A'} />
          <RecordDetailAttribute attribute="Final Payment Deadline" value={userRecord.finalPaymentDeadline ? formatDateString(userRecord.finalPaymentDeadline) : 'N/A'} />

          <Grid container direction='row' marginY={2}>
            <Grid item xs={12} lg={3}>
              <Typography variant='body1' sx={{ fontWeight: 600 }}>User profile picture</Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              {
                userRecord?.avatarUrl ? 
                  <Avatar alt={userRecord.fullName} src={userRecord.avatarUrl} sx={{ width: 80, height: 80 }} /> :
                  <AccountCircleIcon />
              }
            </Grid>
          </Grid>
        </Box>

        <RecordDetailHeader
          title="Emergency Contact"
          description=""
          editPath={createEditPath(ROUTER_URLS.admin.users.editEmergencyContact,)}
        />
        <Box sx={{ marginX: 5 }}>
          <RecordDetailAttribute attribute="Contact Name" value={userRecord.emergencyContactName ?? 'N/A'} />
          <RecordDetailAttribute attribute="Contact Number" value={userRecord.emergencyContactNumber ?? 'N/A'} />
        </Box>

        <RecordDetailHeader
          title="Payment Details"
          description=""
          editPath={createEditPath(ROUTER_URLS.admin.users.editUsageFees)}
          // editPath={createEditPath(ROUTER_URLS.admin.users.edit)}
        />
        <Box sx={{ marginX: 5 }}>
          <RecordDetailAttribute attribute="Stripe Customer Id" value={userRecord.stripeCustomerId ?? 'N/A'} />
          <RecordDetailAttribute attribute="Stripe Connect Id" value={userRecord.stripeConnectId ?? 'N/A'} />
          <RecordDetailAttribute attribute="Marketplace User Fee %" value={(userRecord.marketplaceFee?.userChargePercent !== null && userRecord.marketplaceFee?.userChargePercent !== undefined) ? `${userRecord.marketplaceFee.userChargePercent.toString()}%` : 'Default'} />
          <RecordDetailAttribute attribute="Marketplace Host Fee %" value={(userRecord.marketplaceFee?.hostChargePercent !== null && userRecord.marketplaceFee?.hostChargePercent !== undefined) ? `${userRecord.marketplaceFee.hostChargePercent.toString()}%` : 'Default'} />
          <RecordDetailAttribute attribute="HuntPay User Fee %" value={(userRecord.bookNowFee?.userChargePercent !== null && userRecord.bookNowFee?.userChargePercent !== undefined) ? `${userRecord.bookNowFee.userChargePercent.toString()}%` : 'Default'} />
          <RecordDetailAttribute attribute="HuntPay Host Fee %" value={(userRecord.bookNowFee?.hostChargePercent !== null && userRecord.bookNowFee?.hostChargePercent !== undefined) ? `${userRecord.bookNowFee.hostChargePercent.toString()}%` : 'Default'} />
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingY: 2, marginX: 5, marginTop: 5 }}>
          <Grid container direction='row'>
            <Grid item lg={12} xs={12}>
              <Typography variant="h5" marginY={1}>DANGER - Unreversable admin functions</Typography>
              <Typography variant="body1">Please confirm you know the result of each action prior to clicking</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginX: 5 }}>
          <Grid container direction='row' marginY={2}>
            <Grid item xs={12} lg={6}>
              <Typography variant='body1'>Clears the connection betweeen {APP_DISPLAY_NAME} apps and stripe connect for user</Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Button variant='contained' color='error' disabled>
                Remove Stripe Connect Account
              </Button>
            </Grid>
          </Grid>
          <Grid container direction='row' marginY={2}>
            <Grid item xs={12} lg={6}>
              <Typography variant='body1'>Reset the password for the user. Changing the password will prevent user from logging in until they have the new password.</Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Button variant='contained' color='error' onClick={() => { navigateToPath(ROUTER_URLS.admin.users.editPassword) }}>
                Change Password
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </DashboardLayout>
  );
}

export default withCommonTools(AdminUser);
