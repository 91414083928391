import React from 'react';
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import Dashboard from '../components/layouts/Dashboard/Dashboard';
// import SignificantNumberCard from '../components/compounds/SignificantNumberCard/SignificantNumberCard';
// import CustomLink from '../components/atoms/CustomLink/CustomLink';
// import { ROUTER_URLS } from '../constants/router-urls';
import { withCommonTools } from '../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../components/compounds/CommonWrapper/withCommonTools';
import { useFetchDashboardMetrics } from '../hooks/fetchDashboard';
import SalesSummaryChartCard from '../components/compounds/SalesSummaryChart/SalesSummaryChart';
import ListingViewsPieChart from '../components/compounds/ListingViewsPieChart/ListingViewPieChart';
import RecentDepositsCard from '../components/compounds/RecentDepositsCard/RecentDepositsCard';
import UpcomingBookingsCard from '../components/compounds/UpcomingBookingsCard/UpcomingBookingsCard';
import QuickShareCard from '../components/compounds/QuickShareCard/QuickShareCard';

interface IProps extends WrappedProps {};

function Home({ getConfig }: IProps) {
  const { data: dashboardMetrics, isLoading: dashboardMetricsLoading } = useFetchDashboardMetrics(getConfig);

  return (
    <Dashboard>
      <Stack spacing={3} marginTop={2}>
        <div>
          <Grid container direction='row' spacing={2}>
            <Grid item lg={4} xs={12}>
              <RecentDepositsCard getConfig={getConfig} />
            </Grid>
            <Grid item lg={4} xs={12}>
              <UpcomingBookingsCard getConfig={getConfig} />
            </Grid>
            <Grid item lg={4} xs={12}>
              <QuickShareCard />
            </Grid>
          </Grid>
        </div>
        
        <div>
          <Grid container direction='row' spacing={2}>
            <Grid item lg={6} xs={12}>
              <Paper elevation={2} sx={{ borderRadius: 3 }}>
                <Box width='100%' sx={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', borderBottom: 1, borderColor: 'divider' }}>
                  <Typography
                    variant='body1'
                    component='p'
                    align='center'
                    sx={{
                      mt: 3,
                      mb: 3,
                      display: 'flex',
                      justifyContent: 'center',
                      justifyItems: 'center',
                      width: '75%',
                      alignItems: 'center',
                      fontWeight: 600,
                    }}
                  >
                    Sales Summary
                  </Typography>
                </Box>
                <SalesSummaryChartCard isLoading={dashboardMetricsLoading} salesSummaryData={dashboardMetrics?.salesSummaryData} />
              </Paper>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Paper elevation={2} sx={{ borderRadius: 3 }}>
                <Box width='100%' sx={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', borderBottom: 1, borderColor: 'divider' }}>
                  <Typography
                    variant='body1'
                    component='p'
                    align='center'
                    sx={{
                      mt: 3,
                      mb: 3,
                      display: 'flex',
                      justifyContent: 'center',
                      justifyItems: 'center',
                      width: '75%',
                      alignItems: 'center',
                      fontWeight: 600,
                    }}
                  >
                    Top Package Views
                  </Typography>
                </Box>
                <ListingViewsPieChart isLoading={dashboardMetricsLoading} listingViewChartData={dashboardMetrics?.listingViewChartData} />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Stack>
    </Dashboard>
  );
}

export default withCommonTools(Home);
