import React from 'react';
import { Box, Typography } from '@mui/material';
import { APP_DISPLAY_NAME } from '../../../constants/app-name';

export function PaymentFailedMessage() {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, }}>
      <Typography variant="body1" align='center' color='error' fontWeight={600}>The Client's payment method failed. Please contact {APP_DISPLAY_NAME} at: 844-688-7437</Typography>
    </Box>
  )
}