import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Grid, TextField } from '@mui/material';
import HorizontalStepper from '../../components/atoms/HorizontalStepper/HorizontalStepper';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { newListingSteps } from '../../constants/listings';
import { useFetchListing } from '../../hooks/fetchListing';
import type { ListingDetailType } from '../../types/listing';
import AddableAutocomplete from '../../components/atoms/AddableAutocomplete/AddableAutocomplete';
import HorizontalStepperActions from '../../components/atoms/HorizontalStepperActions/HorizontalStepperActions';
import { ROUTER_URLS } from '../../constants/router-urls';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
// import toProperCase from '../../utils/strings';
import { listingStepOneValid } from '../../utils/listing';

interface IProps extends WrappedProps {};

export const removeInvalidOptions = (simpleArray: Array<string | undefined>): string[] => (
  simpleArray.filter((value) => Boolean(value) && value !== ',') as string[]
);

function EditListing({ getConfig, postConfig, getCurrentUserId }: IProps) {
  const { id: listingId } = useParams();
  const navigate = useNavigate();

  const activeStep = 0;
  const [title, setTitle] = useState('');
  const [keywords, setKeywords] = useState<string[]>([]);
  const [nickname, setNickname] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const { data: listingRecord, isLoading } = useFetchListing(getConfig, listingId ?? '');

  useEffect(() => {
    if (listingRecord) {
      // if (getCurrentUserId()?.toString() !== listingRecord.host.id.toString()) {
      //   const path = generatePath(ROUTER_URLS.listings.show, { id: listingId });
      //   navigate(path, { state: { policyError: true } });
      // }

      setTitle(listingRecord.name ?? '');
      const validKeywords = removeInvalidOptions(listingRecord.keywords);
      setKeywords(validKeywords);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [listingRecord])

  const handleNext = () => {
    const nextUrl = generatePath(ROUTER_URLS.listings.editAddress, { id: listingId });
    navigate({
      pathname: nextUrl,
    });
  };

  const handleNicknameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNickname(event.target.value);
  }

  const handleSave = (moveToNextStep: boolean) => {
    setIsSaving(true);
    const params = {
      listing: {
        name: title,
        host_app_display_name: nickname,
        keywords,
        published: true,
      }
    };

    axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${listingId ?? ''}`, params, postConfig).then((response) => {
      const newListingData: ListingDetailType = response.data;
      if (newListingData.id) {
        if (moveToNextStep) handleNext();
        setIsSaving(false);        
      } else {
        console.log('error failed to save');
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleBack = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // navigate to view listing 
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  }

  // @todo: make this look nice with a link back to the listings page.
  // this should be returned if the listing id is null empty or undefined as well
  if (!listingRecord) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout loading={isLoading || isSaving}>
      <Grid container direction='column'>
        <Grid item marginTop={5} marginBottom={10}>
          <HorizontalStepper
            activeStep={activeStep}
            steps={newListingSteps}
            listingId={listingId}
          />
          <Grid container direction='column' spacing={2} padding={2}>
            <Grid item>
              <Grid container direction='row' spacing={5}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Title"
                    value={title}
                    onChange={handleTitleChange}
                    fullWidth
                    placeholder="Example: Whitetail hunting Iowa 2000 acres"
                    helperText="This title will appear in search and be seen by potential guests"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    id="outlined-required"
                    label="Nickname"
                    value={nickname}
                    onChange={handleNicknameChange}
                    fullWidth
                    helperText="Set how you want the title to appear on your calendar"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction='row' spacing={5}>
                <Grid item xs={12} lg={12}>
                  <AddableAutocomplete
                    value={keywords}
                    setValue={setKeywords}
                    defaultOptions={[]}
                    label="Keywords"
                    helperText="Add tags to your package by typing and selecting them"
                    required={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          
          <Grid container direction='row' spacing={5} justifyContent='center'>
            <Grid item xs={10} lg={10} marginTop={5}>
              <HorizontalStepperActions
                firstStep={true}
                finalStep={false}
                handleNext={handleSave}
                handleBack={handleBack}
                isValid={listingStepOneValid(title)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default withCommonTools(EditListing);
