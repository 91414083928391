import { useMediaQuery } from "@mui/material";
import { APP_DISPLAY_NAME, APP_SQAURE_LOGO } from "../../../constants/app-name";
import theme from "../../../theme/theme";

export default function SquareLogo() {
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  if (matches || APP_DISPLAY_NAME.toLowerCase().replaceAll(' ', '') !== 'huntpay') {
    return (
      <img src={APP_SQAURE_LOGO} alt="welcome square" height={125} />
    )
  }

  return (
    <img src={APP_SQAURE_LOGO} alt="welcome square" height={75} />
  )
}