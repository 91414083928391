import React from 'react';
import { Alert, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import Dashboard from '../../components/layouts/Dashboard/Dashboard';
import LoadingSpinner from '../../components/atoms/LoadingSpinner/LoadingSpinner';
import { useFetchInvoices, useFetchInvoicesEnabled } from '../../hooks/fetchInvoice';
import formatCurrency from '../../utils/currency';
// import NewInvoiceModal from '../../components/compounds/Invoice/NewInvoiceModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { formatDateString } from '../../utils/dates';
import { ROUTER_URLS } from '../../constants/router-urls';
import StripeSetupRequired from '../../components/compounds/StripeSetupRequired/StripeSetupRequired';
import { useLocation } from 'react-router-dom';
import { APP_DISPLAY_NAME } from '../../constants/app-name';

interface IProps extends WrappedProps {};

function Waivers({ getConfig, postConfig }: IProps) {
  const { state } = useLocation();
  const { sendStatus, toastMessage } = state || {};

  const { data: invoices, isLoading } = useFetchInvoices(getConfig);
  const { data: invoicesEnabled, isLoading: isPermissionLoading } = useFetchInvoicesEnabled(getConfig);


  if (!isPermissionLoading && !invoicesEnabled?.hostConnectId) {
    return (
      <Dashboard>
        <Grid container paddingY={2} marginTop={5} alignItems='center'>
          <Grid item xs={6}>
            <Typography variant="h5">Invoices</Typography>
          </Grid>
        </Grid>
        <StripeSetupRequired
          message={`Thanks for choosing ${APP_DISPLAY_NAME} to send your invoices! Before you can begin sending invoices, you need to update some information in Stripe.`}
          updateInfoPrompt='Update Stripe information'
        />
      </Dashboard>  
    )
  }

  return (
    <Dashboard loading={ isLoading || isPermissionLoading }>
      { sendStatus && sendStatus === 'success' && <Alert icon={<CheckCircleIcon fontSize="inherit" />} severity="success">
        { toastMessage }
      </Alert>}
      <Grid container paddingY={2} marginTop={1} alignItems='center'>
        <Grid item xs={6}>
          <Typography variant="h5">Invoices</Typography>
        </Grid>
        <Grid item xs={6} display='flex' justifyContent='end'>
          
            <Button
              type="submit"
              variant="contained"
              href={ROUTER_URLS.invoices.new}
              color='primary'
              sx={{ marginRight: 2 }}
            >
              Send Invoice
            </Button>
        </Grid>
      </Grid>
      { isLoading ? <LoadingSpinner /> : <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: 600 }}>Status</TableCell>
              <TableCell align="left" sx={{ fontWeight: 600 }}>Sent To</TableCell>
              <TableCell align="left" sx={{ fontWeight: 600 }}>Sent On</TableCell>
              <TableCell align="left" sx={{ fontWeight: 600 }}>Amount Paid</TableCell>
              <TableCell align="left" sx={{ fontWeight: 600 }}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices?.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  hover={true}
                  
                >
                  <TableCell align="left">
                    { row.status === 'completed' ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}
                  </TableCell>
                  <TableCell align="left">{row.recipient.fullName}</TableCell>
                  <TableCell align="left">{formatDateString(row.createdAt)}</TableCell>
                  <TableCell align="left">{`$${formatCurrency(row.paidAmount / 100)}`}</TableCell>
                  <TableCell align="left">{`$${formatCurrency(row.payerTotal / 100)}`}</TableCell>
                </TableRow>
              )}
            )}
          </TableBody>
        </Table>
      </TableContainer>}
      <div>
        {
          // <NewInvoiceModal
          // open={open}
          // onClose={handleClose}
          // postConfig={postConfig}
          // />
        }
      </div>
    </Dashboard>
  );
}

export default withCommonTools(Waivers);