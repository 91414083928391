import React from 'react';
import type { MouseEvent } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import LeftDrawer from './LeftDrawer';
import NavBar from './NavBar';
import { useSettingsDispatchContext } from '../../../contexts/SettingsContext';

// import type { SettingType } from '../../../types/setting';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import LoadingSpinner from '../../atoms/LoadingSpinner/LoadingSpinner';
import { Alert, Button, Typography } from '@mui/material';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';

import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import { doLogout } from '../../../api/auth';
import { useAuthHeader, useSignOut } from 'react-auth-kit';

const drawerWidth = 275;

interface DashboardProps {
  children?: React.ReactNode;
  loading?: boolean;
  errorMessage?: string;
  errorHelpSteps?: React.ReactNode;
};

interface DashboardProps extends WrappedProps {};

// used styled components to get extremely particular styling in place for the layout
// for additional reading: https://mui.com/system/styled/#difference-with-the-sx-prop
const Contents = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
    },
  }),
}));

function DashboardLayout({ children, loading, errorMessage, errorHelpSteps, getConfig, postConfig }: DashboardProps) {
  const settingsDispatchContext: any = useSettingsDispatchContext();
  const theme = useTheme();
  const signOut = useSignOut();
  const authHeader = useAuthHeader();
  const token = authHeader();

  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [open, setOpen] = React.useState(matches);

  const [userConfirmed, setUserConfirmed] = React.useState(true);
  const [unreadMessageCount, setUnreadMessageCount] = React.useState(0);
  const [userProfileImage, setUserProfileImage] = React.useState<string>();
  const [userFullName, setUserFullName] = React.useState('');

  const toggleDrawer = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingsData = await axios.get(API_V3_ALLIGATOR_URLS.cancellationPolicies.list, getConfig);
        // @todo handle errors, etc.
        settingsDispatchContext({
          type: 'settingsChanged',
          cancellationPolicies: settingsData.data,
        });
      } catch (e) {
        console.log(e);
      }
    }
    fetchSettings().catch((e) => { console.log(e) });
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, []);

  React.useEffect(() => {
    axios.get(API_V3_ALLIGATOR_URLS.dashboard.countsAndNotifications, getConfig).then((res) => {
      setUserConfirmed(res.data.userConfirmed);
      setUnreadMessageCount(res.data.unreadMessageCount);
      setUserProfileImage(res.data.userProfileImage);
      setUserFullName(res.data.userFullName);
    }).catch((e) => {
      if (e?.response?.status === 401) {
        doLogout(token).then((r) => {
          signOut();
        }).catch((e) => {
          console.log(e);
        });
      }
      console.log(e);
    });
  }, [])

  const resendEmail = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    axios.post(API_V3_ALLIGATOR_URLS.auth.resendConfirmEmail, {}, postConfig).then((res) => {
      console.log('resent');
    }).catch((e) => {
      console.log(e);
    })
  }

  return (
    <Box display="flex" flexDirection="column" sx={{ flexGrow: 1 }}>
      <Box>
        <NavBar
          toggle={toggleDrawer}
          unreadMessageCount={unreadMessageCount}
          userProfileImage={userProfileImage}
          userFullName={userFullName}
        />
      </Box>
      <Box>
        <LeftDrawer open={open} />
        <Contents open={open}>
          <Toolbar />
          {!userConfirmed && <Box sx={{ marginTop: 2, marginLeft: 1, marginRight: 1 }}>
            <Alert
              severity="warning"
              sx={{ padding: 2 }}
            >
              You have not confirmed your email address.
              Please check your email and confirm the address by clicking the confirm
              button in your welcome email to recieve hunt notifications and alerts.
              <Button variant='text' onClick={resendEmail} sx={{ marginX: 1, paddingX: 0, textTransform: 'none', textDecoration: 'underline' }}>Click here</Button> to resend the confirmation email.
            </Alert>
          </Box>}
          <Box paddingY={2} paddingX={{ xs: 0, md: 2 }}>
            {
              loading ? <LoadingSpinner /> :
              (errorMessage ?
                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 10, alignItems: 'center', justifyItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                  <Typography variant='h6' marginBottom={3}>{ errorMessage }</Typography>
                  {errorHelpSteps}
                </Box> :
                children)
            }
          </Box>
        </Contents>
      </Box>
    </Box>
  );
}

export default withCommonTools(DashboardLayout);