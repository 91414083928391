import React, { useState } from 'react';
import type { MouseEvent } from 'react';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import { useFetchAdminBookings } from '../../../hooks/fetchBooking';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import { ROUTER_URLS } from '../../../constants/router-urls';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import toProperCase, { snakeToProper } from '../../../utils/strings';
import { formatDateString } from '../../../utils/dates';
import Dashboard from '../../../components/layouts/Dashboard/Dashboard';
import BugReportIcon from '@mui/icons-material/BugReport';
import EditIcon from '@mui/icons-material/Edit';
import { format, formatISO } from 'date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { useUserCanAccess } from '../../../hooks/userCanAccess';
import FormattedDataGrid from '../../../components/compounds/FormattedDataGrid/FormattedDataGrid';
import type { GridAlignment, GridPaginationModel, GridSortModel, GridValueGetterParams } from '@mui/x-data-grid';
import { GridActionsCellItem } from '@mui/x-data-grid';
import type { BookingDetailType } from '../../../types/booking';

const BOOKING_STATUSES = ['All', 'Requested', 'Approved', 'Denied', 'Completed', 'Error', 'Cancelled', 'Imported'];
const BOOKING_SOURCES = [
  { key: 'all', display: 'All' },
  { key: 'marketplace', display: 'Marketplace' },
  { key: 'host_added', display: 'Host added' },
  { key: 'book_now', display: 'Hunt Pay' },
];

interface IProps extends WrappedProps {};

function AdminBookings({ getConfig, postConfig }: IProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const userCanDownload = useUserCanAccess('tripsExport');
  const userCanEdit = useUserCanAccess('tripEdit');

  const [filterStatus, setFilterStatus] = useState('All');
  const [tripSource, setTripSource] = useState('all');
  const [userName, setUserName] = useState<string>('');
  const [hostName, setHostName] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [exportLoading, setExportLoading] = useState(false);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'startDate',
      sort: 'desc',
    },
  ]);

  const userId = searchParams.get('userId');
  const hostId = searchParams.get('hostId');

  const { data: bookingsData, isLoading, refetch } = useFetchAdminBookings(getConfig, {
    booking: {
      host_name: hostName.trim(),
      user_name: userName.trim(),
      status: filterStatus === 'All' ? '' : filterStatus.toLowerCase(),
      source: tripSource === 'all' ? '' : tripSource,
      start_date: startDate ? formatISO(startDate) : '',
      end_date: endDate ? formatISO(endDate) : '',
      user_id: userId,
      host_id: hostId,
    },
    page: paginationModel.page + 1,
    sort: sortModel[0],
  });

  const handleSearch = () => {
    setPaginationModel({ page: 0, pageSize: 20 });
    refetch().catch((e) => { console.log(e) });
  };

  const handleExport = () => {
    if (!userCanDownload) return;

    setExportLoading(true);
    const config: any = { ...postConfig, responseType: 'blob' };
    const params = {
    }
    
    axios.post(API_V3_ALLIGATOR_URLS.admin.bookings.export, params, config).then((response) => {
      const csvBlob = new Blob([response.data], { type: 'application/csv' });
      const csvUrl = window.URL.createObjectURL(csvBlob);
      const tempLink = document.createElement('a');
      tempLink.href = csvUrl;
      tempLink.setAttribute(
        'download',
        `trips-export-${format(new Date, 'M-d-yy')}.csv`
      )
      document.body.appendChild(tempLink);
      tempLink.click();
      
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(csvUrl);
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setExportLoading(false);
    });
  }

  const handleFilterStatusChange = (event: SelectChangeEvent) => {
    setFilterStatus(event.target.value);
  };

  const handleSourceStatusChange = (event: SelectChangeEvent) => {
    setTripSource(event.target.value);
  };

  const navigateToDebugBooking = (e: MouseEvent<HTMLElement>, id: number) => {
    e.stopPropagation();
    const path = generatePath(ROUTER_URLS.admin.bookings.debug, { id });
    navigate(path);
  };

  const navigateToEditBooking = (e: MouseEvent<HTMLElement>, id: number) => {
    e.preventDefault();
    e.stopPropagation();
    const path = generatePath(ROUTER_URLS.admin.bookings.edit, { id });
    console.log(id);
    navigate(path);
  };

  const handleSortModelChange = React.useCallback((newSortModel: GridSortModel) => {
    const copiedSortModel = [...newSortModel];
    setSortModel(copiedSortModel);
  }, []);

  const handlePaginationChange = (newPaginationModel: GridPaginationModel) => {
    setPaginationModel(newPaginationModel);
  }

  const gridHeaders = [
    {
      field: 'listingName',
      headerName: 'Package',
      minWidth: 200,
      flex: 1,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.listing.name,
    },
    {
      field: 'hostName',
      headerName: 'Outfitter',
      width: 150,
      editable: false,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.host.name ? toProperCase(params.row.host.name) : '',
    },
    {
      field: 'hostEmail',
      headerName: 'Outfitter Email',
      width: 150,
      editable: false,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.host.email,
    },
    {
      field: 'guestName',
      headerName: 'Hunter',
      width: 150,
      editable: false,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.user.name ? toProperCase(params.row.user.name) : '',
    },
    {
      field: 'guestEmail',
      headerName: 'Hunter Email',
      width: 150,
      editable: false,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.user.email,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      editable: false,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => snakeToProper(params.row.status),
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => `${formatDateString(params.row.startDate)} - ${formatDateString(params.row.endDate)}`,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      minWidth: 100,
      cellClassName: 'actions',
      align: 'right' as GridAlignment,
      getActions: ({ id, row }: { id: string | number, row: BookingDetailType }) => {
        const actionBlocks: JSX.Element[] = []

        if (userCanEdit) {
          actionBlocks.push(
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={(e: any) => { navigateToEditBooking(e, row.id) }}
              color="primary"
            />,
          )
        }
        return actionBlocks.concat(
          [
            <GridActionsCellItem
              icon={<BugReportIcon />}
              label="View"
              className="textPrimary"
              onClick={(e: any) => { navigateToDebugBooking(e, row.id) }}
              color="warning"
            />,
          ]
        );
      },
    },
  ]

  const ListDisplayGrid = (
    <FormattedDataGrid
      headers={gridHeaders}
      rows={bookingsData?.bookings ?? []}
      totalRows={bookingsData?.page.count ?? 0}
      pagingModel={paginationModel}
      setPagingModel={handlePaginationChange}
      isLoading={isLoading}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 20,
          },
        },
        sorting: {
          sortModel: [
            { field: 'startDate', sort: 'desc' }
          ]
        }
      }}
      sortModel={sortModel}
      handleSortModelChange={handleSortModelChange}
    />
  )

  return (
    <Dashboard>
      <Grid container paddingY={2} marginTop={5} alignItems='center'>
        <Grid item xs={6}>
          <Typography variant="h5">Hunts</Typography>
        </Grid>
        <Grid item xs={6} display='flex' justifyContent='end'>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={2} sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControl fullWidth>
            <InputLabel id="booking-status-label">Status</InputLabel>
            <Select
              labelId="booking-status-label"
              id="booking-status-select"
              value={filterStatus}
              label="Status"
              onChange={handleFilterStatusChange}
              fullWidth
            >
              { BOOKING_STATUSES.map((status: string) => (
                <MenuItem key={status} value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>  
        </Grid>
        <Grid item xs={12} lg={2} sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControl fullWidth>
            <InputLabel id="booking-status-label">Source</InputLabel>
            <Select
              labelId="booking-source-label"
              id="booking-source-select"
              value={tripSource}
              label="Source"
              onChange={handleSourceStatusChange}
              fullWidth
            >
              { BOOKING_SOURCES.map((source) => (
                <MenuItem key={source.key} value={source.key}>{source.display}</MenuItem>
              ))}
            </Select>
          </FormControl>  
        </Grid>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid item xs={12} lg={3}>
            <DatePicker
              label="Filter Start Date"
              value={startDate}
              onChange={(newValue) => { setStartDate(newValue) }}
              sx={{ width: '100%' }}
              slotProps={{
                field: { clearable: true, onClear: () => { setStartDate(null) } },
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <DatePicker
              label="Filter End Date"
              value={endDate}
              onChange={(newValue) => { setEndDate(newValue) }}
              sx={{ width: '100%' }}
              slotProps={{
                field: { clearable: true, onClear: () => { setEndDate(null) } },
              }}
            />
          </Grid>
        </LocalizationProvider>
        <Grid item xs={12} lg={4} sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            id="host-email-input"
            label="Outfitter First Name"
            placeholder="Outfitter Name"
            onChange={(e) => { setHostName(e.target.value) }}
            value={hostName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={4} sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            id="host-email-input"
            label="User Name"
            placeholder="User Name"
            onChange={(e) => { setUserName(e.target.value) }}
            value={userName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={3}>
            <Button
              type="submit"
              variant="contained"
              sx={{ height: '45px', marginY: 3, marginLeft: 3, }}
              onClick={handleSearch}
            >
              Search
            </Button>
            {userCanDownload && <Button
              onClick={handleExport}
              disabled={exportLoading}
              variant='outlined'
              sx={{ height: '45px', marginY: 3, marginLeft: 3, }}
            >
              Download
            </Button>}
        </Grid>
      </Grid>
      { ListDisplayGrid }
    </Dashboard>
  );
}

export default withCommonTools(AdminBookings);