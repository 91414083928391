import { teal, cyan, green, yellow, orange, brown, blueGrey, red, purple, blue, deepPurple, indigo } from '@mui/material/colors';

export const LISTING_COLOR_PALLETE = [
  teal[500],
  deepPurple[500],
  orange[500],
  red[500],
  green[500],
  cyan[500],
  yellow[500],
  brown[500],
  purple[500],
  blue[500],
  indigo[500],
  blueGrey[500],
];


// "#009688",
// "#673ab7",
// "#ff9800",
// "#f44336",
// "#4caf50",
// "#00bcd4",
// "#ffeb3b",
// "#795548",
// "#9c27b0",
// "#2196f3",
// "#3f51b5",
// "#607d8b"