import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Fab, FormControl, FormHelperText, Grid, MenuItem, Select, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme} from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import Dashboard from '../../components/layouts/Dashboard/Dashboard';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';
import { dateFromISOString } from '../../utils/dates';
import { addDays, format } from 'date-fns';
// import { generatePath, useNavigate } from 'react-router-dom';
// import { ROUTER_URLS } from '../../constants/router-urls';
import type { BookingResultType } from '../../types/booking';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import listPlugin from '@fullcalendar/list';
import type { EventClickArg, EventInput } from '@fullcalendar/core';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import { useFetchListings } from '../../hooks/fetchListing';
import AddIcon from '@mui/icons-material/Add';

import CalendarTripModal from '../../components/compounds/CalendarTripModal/CalendarTripModal';
import { ROUTER_URLS } from '../../constants/router-urls';
import ChangeColorModal from '../../components/compounds/ChangeColorModal/ChangeColorModal';
import type { ListingDetailType } from '../../types/listing';
import { listingDisplayName } from '../../utils/listing';
// import { LISTING_COLOR_PALLETE } from '../../constants/listing-colors';

interface IProps extends WrappedProps {};

const calendarViewOptions = [
  { id: 'dayGridMonth', label: 'Month' },
  { id: 'dayGridWeek', label: 'Week' },
  { id: 'dayGridDay', label: 'Day' },
  { id: 'listMonth', label: 'List' },
];

const filterCalendarBookings = (bookings: BookingResultType[]): EventInput[] => {
  return bookings.filter((booking) => !['denied', 'cancelled', 'added_to_cart'].includes(booking.status)).map((booking) => {
    const startDate = dateFromISOString(booking.startDate);
    // we add one day bc we need the calendar to show the last day as having an event on it
    const endDate = addDays(dateFromISOString(booking.endDate), 1);
    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');
    const name = booking.user?.name ? `${booking.user?.name} - ` : '';
    let shortTitle = `${name}${booking.numberOfGuests} Hunters`;
    let longTitle = `${name}${booking.numberOfGuests} Hunters - ${listingDisplayName(booking.listing)}`;
    if (booking.calendarLabel && booking.calendarLabel.length > 0) {
      shortTitle = booking.calendarLabel; 
      longTitle = booking.calendarLabel;
    }

    // let color = 'grey';

    // switch (booking.status) {
    //   case 'building_group':
    //   case 'processing':
    //   case 'needs_payment_verification':  
    //     color = '#E19B3B';
    //     break;
    //   case 'requested':
    //     color = '#D87A36';
    //     break;
    //   case 'approved':
    //     color = '#18737F';
    //     break;
    //   case 'error':
    //     color = '#D34F42';
    //     break;
    //   default:
    //     break;
    // }
    // const name = booking.user?.name ? `${booking.user?.name} - ` : '';
    return {
      title: shortTitle,
      // date: formattedStartDate,
      start: formattedStartDate,
      end: formattedEndDate,
      color: booking.listing.hexColor,
      // status: 'done',
      extendedProps: {
        longTitle,
        id: booking.id,
      }
    };
  });
};

// currently an open bug ticket where events get overlapped about half the time
// function renderEventContent(eventInfo: any) {
//   return(
//     <>
//       <b>{eventInfo.timeText}</b>
//       <i>{eventInfo.event.title}</i>
//     </>
//   )
// }

const mobileHeaderConfig = {
  left: 'prev',
  center: 'title',
  right: 'next',
}

const desktopHeaderConfig = {
  left: 'prev,next today',
  center: 'title',
  right: 'dayGridMonth,dayGridWeek,dayGridDay,listMonth' // user can switch between the two
}

function Calendar({ postConfig, getConfig }: IProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [selectedListingId, setSelectedListingId] = useState<string>('');
  const [selectedListingName, setSelectedListingName] = useState<string>('');
  const [calendarBookings, setCalendarBookings] = useState<EventInput[]>([]);
  const [calendarView, setCalendarView] = useState<string>('dayGridMonth');
  const [hexColor, setHexColor] = useState<string>('');
  const [nickname, setNickname] = useState('');

  const [open, setOpen] = React.useState(false);
  const [colorOpen, setColorOpen] = React.useState(false);
  const [colorLoading, setColorLoading] = useState(false);

  const [selectedTripId, setSelectedTripId] = useState<string>();

  const [timeStamp, setTimeStamp] = useState(new Date().getTime())

  const handleClose = () => {
    setOpen(false);
    setSelectedTripId(undefined);
    setTimeStamp(new Date().getTime());
  };

  const handleColorClose = () => {
    setColorOpen(false);
    // setSelectedTripId(undefined);
    // setTimeStamp(new Date().getTime());
  };

  const calendarRef = useRef<FullCalendar>(null)
  
  const { data: listingRecords, isLoading, refetch: reload } = useFetchListings(postConfig);

  useEffect(() => {
    axios.post(API_V3_ALLIGATOR_URLS.booking.list, { trip: { status: '', listing_id: selectedListingId }}, postConfig).then((response) => {
      const bookingData = response.data;
  
      const filteredCalendarBookings = filterCalendarBookings(bookingData);
      setCalendarBookings(filteredCalendarBookings);
      if (selectedListingId && bookingData.length > 0) {
        setHexColor(bookingData[0].listing.hexColor);
        setNickname(bookingData[0].listing.hostAppDisplayName);
      }
    }).catch((error) => {
      console.log(error);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedListingId, timeStamp]);

  // const navigateToBooking = (id: number) => {
  //   const path = generatePath(ROUTER_URLS.bookings.show, { id });
  //   navigate(path);
  // };

  const handleEventClick = (info: EventClickArg) => {
    setOpen(true);
    setSelectedTripId(info.event.extendedProps.id)
  };

  const handleListingSelectChange = (event: SelectChangeEvent) => {
    const id = event.target.value;
    setSelectedListingId(id);
    
    if (listingRecords) {
      const option = listingRecords.find((record) => record.id.toString() === id);
      if (option) {
        setSelectedListingName(listingDisplayName(option));  
      }
    }
  };

  const handleCalendarViewChange = (selectedOption: string) => {
    setCalendarView(selectedOption);
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()
      calendarApi.changeView(selectedOption);
    }
    
  };

  const handleSave = () => {
    setColorLoading(true);
    if (selectedListingId) {
      const params = {
        listing: { hex_color: hexColor, host_app_display_name: nickname }
      };

      axios.patch(`${API_V3_ALLIGATOR_URLS.listing.update}${selectedListingId}`, params, postConfig).then((response) => {
        const newListingData: ListingDetailType = response.data;
        if (newListingData.id) {
          setColorLoading(false);
          if (colorOpen) {
            setTimeStamp(new Date().getTime());
            reload().catch((e) => { console.log(e) });
            handleColorClose()
          }
        } else {
          console.log('error failed to save');
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  };

  const headerConfig = !matches ? mobileHeaderConfig : desktopHeaderConfig;

  return (
    <Dashboard loading={colorLoading}>
      <Grid container marginTop={3} marginBottom={10} spacing={3} sx={{ minHeight: '450px' }}>
        <Grid item xs={12} sm={6}>
          { !isLoading &&
            <FormControl fullWidth>
              <Select
                labelId="listing-label"
                id="listing-select"
                value={selectedListingId}
                onChange={handleListingSelectChange}
                fullWidth
                placeholder='All Packages'
                displayEmpty
              >
                <MenuItem value="">
                  All Packages
                </MenuItem>
                {listingRecords && listingRecords.length > 0 && listingRecords.map((listing) => {
                  return (
                    <MenuItem key={listing.id} value={listing.id.toString()}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ backgroundColor: listing.hexColor, height: '20px', width: '20px', borderRadius: 1 }} /><Typography>{listingDisplayName(listing)}</Typography>
                      </Box>
                    </MenuItem>
                  )
                })}
              </Select>
              {!selectedListingId && <FormHelperText>Select a package to change the color</FormHelperText>}
            </FormControl>
          }
          {selectedListingId && <Button variant='text' onClick={() => { setColorOpen(true) }} sx={{ textTransform: 'none' }}>Change color or name</Button>}
        </Grid>
        {matches && <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignContent: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Button href={ROUTER_URLS.bookings.new} startIcon={<AddIcon />} variant='contained' color='secondary'>
              Add Trip
            </Button>
          </Box>
        </Grid>}
        { !matches &&
          <Grid item xs={12} lg={5} sx={{ display: 'flex', alignItems: 'center' }}>
            <ToggleButtonGroup
              color="primary"
              fullWidth
              value={calendarView}
              exclusive
              onChange={(event: React.MouseEvent<HTMLElement>, value: string | null) => {
                if (value) {
                  handleCalendarViewChange(value);
                }
              }}
              aria-label="Platform"
            >
              { calendarViewOptions.map((option) => (
                <ToggleButton
                  key={option.id}
                  value={option.id}
                >
                  {option.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
        }
        <Grid item xs={12} sx={{ minHeight: '450px' }}>
          <FullCalendar
            ref={calendarRef}
            plugins={[ dayGridPlugin, listPlugin ]}
            initialView=""
            events={calendarBookings}
            contentHeight='auto'
            height='auto'
            eventClick={handleEventClick}
            // headerToolbar={{
            //   left: 'prev,next today',
            //   center: 'title',
            //   right: 'dayGridMonth,dayGridWeek,dayGridDay,listMonth' // user can switch between the two
            // }}
            headerToolbar={headerConfig}
            dayMaxEventRows={true}
            views={{
              dayGridMonth: {
                dayMaxEventRows: 2
              }
            }}
            eventDidMount={(info) => {

              var timeTableCell = info.el.getElementsByClassName('fc-list-event-time') as HTMLCollectionOf<HTMLElement>;
              if (timeTableCell[0]) {
                timeTableCell[0].style.display = 'none'
              }
              var titleTableCell = info.el.getElementsByClassName('fc-list-event-title') as HTMLCollectionOf<HTMLElement>;
              if (titleTableCell[0]) {
                titleTableCell[0].innerText = info.event.extendedProps.longTitle
              }
              // if (info.view.type === 'dayGridMonth') {
              //   info.event.setProp("title", info.event.extendedProps.longTitle);
              // }


              // if (info.event.extendedProps.status === 'done') {
// 
              //   // Change background color of row
              //   info.el.style.backgroundColor = 'red';
          // 
              //   // Change color of dot marker
              //   var dotEl = info.el.getElementsByClassName('fc-event-dot') as HTMLCollectionOf<HTMLElement>;
              //   if (dotEl[0]) {
              //     dotEl[0].style.backgroundColor = 'white';
              //   }
              // }
            }}
            // eventContent={renderEventContent}
          />
        </Grid>
        {!matches && <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <Fab
            color="secondary"
            aria-label="add"
            sx={{
              position: "fixed",
              bottom: (theme) => theme.spacing(2),
              right: (theme) => theme.spacing(2)
            }}
            href={ROUTER_URLS.bookings.new}
          >
            <AddIcon />
          </Fab>
        </Box>}
      </Grid>
      <div>
        <CalendarTripModal
          open={open}
          onClose={handleClose}
          tripId={selectedTripId}
          getConfig={getConfig}
        />
      </div>
      <div>
        { selectedListingId &&
          <ChangeColorModal
            listingId={selectedListingId}
            listingName={selectedListingName}
            listingColor={hexColor}
            setListingColor={setHexColor}
            onClose={handleColorClose}
            open={colorOpen}
            getConfig={getConfig}
            handleSave={handleSave}
            nickname={nickname}
            setNickname={setNickname}
          />
        }
      </div>
    </Dashboard>
  );
}

export default withCommonTools(Calendar);
