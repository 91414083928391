import React from 'react';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import { generatePath, useNavigate } from 'react-router-dom';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import { useFetchUser } from '../../hooks/fetchUser';
import { Avatar, Box, Button, Grid, Stack, Typography } from '@mui/material';
import RecordDetailHeader from '../../components/atoms/RecordDetailHeader/RecordDetailHeader';
import { ROUTER_URLS } from '../../constants/router-urls';
import RecordDetailAttribute from '../../components/atoms/RecordDetailAttribute/RecordDetailAttribute';
import { formatDateString } from '../../utils/dates';
import { displayPhoneNumber } from '../../utils/phoneNumber';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StripeConnectButton from '../../components/compounds/StripeConnectButton/StripeConnectButton';
import { APP_DISPLAY_NAME } from '../../constants/app-name';

interface IProps extends WrappedProps {};

function UserProfile({ getConfig }: IProps) {
  const navigate = useNavigate();

  const { data: userRecord, isLoading } = useFetchUser(getConfig);

  const createEditPath = (editPath: string) => {
    return generatePath(editPath);
  };

  const handleChangePasswordClick = () => {
    const path = createEditPath(ROUTER_URLS.user.editPassword);
    navigate(path);
  };

  if (!userRecord) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout loading={isLoading}>
      <Stack>
        <RecordDetailHeader
          title="Profile Details"
          description=""
          editPath={createEditPath(ROUTER_URLS.user.edit)}
        >
        </RecordDetailHeader>
        <Box sx={{ marginX: 5 }}>
          <RecordDetailAttribute attribute="First Name" value={userRecord.firstName} />
          <RecordDetailAttribute attribute="Last Name" value={userRecord.lastName} />
          <RecordDetailAttribute attribute="Short Bio" value={userRecord.shortBio ?? 'N/A'} />
          <RecordDetailAttribute attribute="Full Bio" value={userRecord.longBio ?? 'N/A'} />
          <Grid container direction='row' marginY={2}>
            <Grid item xs={12} lg={3}>
              <Typography variant='body1' sx={{ fontWeight: 600 }}>User profile picture</Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              {
                userRecord?.avatarUrl ? 
                  <Avatar alt={userRecord.fullName} src={userRecord.avatarUrl} sx={{ width: 80, height: 80 }} /> :
                  <AccountCircleIcon />
              }
            </Grid>
          </Grid>
        </Box>
        <RecordDetailHeader
          title="Account"
          description=""
          editPath={createEditPath(ROUTER_URLS.user.editAccount)}
        >
          <Button variant='contained' color='secondary' onClick={handleChangePasswordClick}>
            Change Password
          </Button>
        </RecordDetailHeader>
        <Box sx={{ marginX: 5 }}>
          <RecordDetailAttribute attribute="Email" value={userRecord.email} />
          <RecordDetailAttribute attribute="Phone Number" value={displayPhoneNumber(userRecord.phoneNumber) ?? ''} />
          <RecordDetailAttribute attribute="Legal Name" value={userRecord.fullName ?? 'N/A'} />
          <RecordDetailAttribute attribute="Timezone" value={userRecord.timeZone ?? 'N/A'} />
          <RecordDetailAttribute attribute="Date of Birth" value={formatDateString(userRecord.dateOfBirth)} />
          <RecordDetailAttribute attribute="Gender" value={userRecord.gender ?? ''} />
        </Box>

        <RecordDetailHeader
          title="Emergency Contact"
          description=""
          editPath={createEditPath(ROUTER_URLS.user.editEmergencyContact)}
        />
        <Box sx={{ marginX: 5 }}>
          <RecordDetailAttribute attribute="Contact Name" value={userRecord.emergencyContactName ?? 'N/A'} />
          <RecordDetailAttribute attribute="Contact Number" value={displayPhoneNumber(userRecord.emergencyContactNumber) ?? 'N/A'} />
        </Box>

        <RecordDetailHeader
          title="Payout Information"
          titleImagePath='/powered_by_stripe.svg'
          description=""
        />
        <Box sx={{ marginX: 5 }}>
          <Grid container direction='row' marginY={2}>
            <Grid item xs={12} lg={6}>
              <Typography variant='body1'>
                {APP_DISPLAY_NAME} uses the secure payment platform Stripe to process all customer accounts and payments.< br/>< br/>
                Since your security is a top concern for us, we will never store your information.< br/>< br/>
                By completing the forms on the next page, you will be able to accept hunt requests and payments as an outfitter on {APP_DISPLAY_NAME}.< br/>< br/>
                If you need assistance, please contact us at murielle@venku.com.< br/>< br/>
                We can't wait to see you Outside!
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6} display='flex' flexGrow={1} alignItems='center'>
              <Box>
                <StripeConnectButton connectAccountSetup={userRecord.connectAccountSetup} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </DashboardLayout>
  );
}

export default withCommonTools(UserProfile);
