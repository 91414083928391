
import { Alert, Box, Button, Container, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import axios from "axios";
import { useState } from "react";
import { API_V3_ALLIGATOR_URLS } from "../../constants/api-urls";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../components/atoms/LoadingSpinner/LoadingSpinner";
import SquareLogo from "../../components/compounds/SquareLogo/SquareLogo";

const successMessage = (
  <Box sx={{ height: 400, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} className='unstyled-link'>
    <Typography textAlign='center' sx={{ maxWidth: 600 }}>
      Password reset successfully. Please use your email and password to login on the next page.
    </Typography>
    <a href='/login'>
      <Button variant='contained' sx={{ textTransform: 'none', width: 100, marginY: 2 }}>
        Sign in
      </Button>
    </a>
  </Box>
)

function ResetPassword() {

  const [searchParams] = useSearchParams();
  const token = searchParams.get('t');
  
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  
  const handleClickShowPassword = () => { setShowPassword((show) => !show) };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmit = (): void => {
    if (submitEnabled()) {
      setIsSaving(true)
      const params = {
        user: {
          reset_password_token: token,
          password: newPassword,
          password_confirmation: confirmPassword,
        }
      };
      axios.patch(API_V3_ALLIGATOR_URLS.auth.forgotPassword, params).then((res) => {
        if (res.status === 200) {
          setUpdateSuccess(true);
          setIsSaving(false)
        } else {
          setErrorText('Unable to log in');
          setIsSaving(false)
        }
      }).catch((e) => {
        console.log(e);
      });
    } else {
      setErrorText("Username and password required");
    }
  };

  const submitEnabled = (): boolean => {
    return newPassword.length > 0 &&
           confirmPassword.length > 0 &&
           newPassword === confirmPassword &&
           Boolean(newPassword.match(/\d/)) &&
           Boolean(newPassword.match(/[a-zA-Z]/))
  }

  if (isSaving) {
    <LoadingSpinner />
  }

  if (updateSuccess) {
    return successMessage;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <SquareLogo />
        <Grid2 container spacing={2}>
          <Grid2 xs={12}>
            { errorText &&
              <Alert severity="error" sx={{ my: 2 }}>{ errorText }</Alert>
            }
          </Grid2>
          <Grid2>
            <Typography component="h1" variant="h5" mt={5}>
              Please reset your password
            </Typography>
          </Grid2>
          <Grid2 xs={12}>
            <FormControl fullWidth required variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                name="password"
                value={newPassword}
                onChange={(e) => { setNewPassword(e.target.value) }}
              />
            </FormControl>
            { newPassword.length > 0 && newPassword.length < 8 &&
              <Alert severity="error" sx={{ my: 2 }}>Password must contain at least 8 characters</Alert>
            }
            { newPassword.length > 0 && newPassword.length > 7 && !newPassword.match(/\d/) &&
              <Alert severity="error" sx={{ my: 2 }}>Password must include at least one number</Alert>
            }
            { newPassword.length > 0 && newPassword.length > 7 && !newPassword.match(/[a-zA-Z]/) &&
              <Alert severity="error" sx={{ my: 2 }}>Password must include at least one letter</Alert>
            }
          </Grid2>
          <Grid2 xs={12}>
            <FormControl fullWidth required variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Confirm New Password</InputLabel>
              <OutlinedInput
                id="confirmPassword"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm New Password"
                name="confirm-new-password"
                value={confirmPassword}
                onChange={(e) => { setConfirmPassword(e.target.value) }}
              />
            </FormControl>

            { newPassword.length > 0 && confirmPassword.length > 0 && newPassword !== confirmPassword &&
              <Alert severity="error" sx={{ my: 2 }}>New password and confirm password do not match</Alert>
            }
          </Grid2>
          <Grid2 xs={12}>
            <Button
              type='button'
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              sx={{ mb: 2 }}
              disabled={!submitEnabled()}
            >
              Save new password
            </Button>
            <Box display='flex' flexDirection='row' alignItems='center' width='100%' className='unstyled-link' sx={{ marginY: 1 }}>
              <Typography sx={{ textTransform: 'none',  }}>
                Already have an account?
                <a href='/login'>
                  <span style={{ textDecoration: 'underline' }}>
                    Log in
                  </span>
                </a>
              </Typography>
            </Box>
          </Grid2>
        </Grid2>
      </Box>
    </Container>
    
  )
}

export default ResetPassword;