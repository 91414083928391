import React, { useState } from "react";
import { Alert, Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useAuthUser, useIsAuthenticated, useSignIn } from 'react-auth-kit';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { doLogin } from '../../api/auth';
import { ROUTER_URLS } from "../../constants/router-urls";
import CustomLink from "../../components/atoms/CustomLink/CustomLink";
import { APP_DISPLAY_NAME } from "../../constants/app-name";
import SquareLogo from "../../components/compounds/SquareLogo/SquareLogo";


interface UserAuthResponseType {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  created_at: number;
  userId: number;
  email: string;
  active: boolean;
  accessHostApp: boolean;
  accessBookNowApp: boolean;
  userType: string;
  fullName: string;
  avatarUrl: string;
}

function LogIn() {
  const isAuthenticated = useIsAuthenticated();
  const signIn = useSignIn();
  const isAuthed = isAuthenticated();
  const navigate = useNavigate();
  const location = useLocation();

  const auth = useAuthUser();
  const userInfo = auth();

  const [errorText, setErrorText ] = useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  
  const successHandler = (response: any) => {
    const { data }: { data: UserAuthResponseType } = response;
    // https://authkit.arkadip.dev/refreshtoken/#returns_1
    // `${this.ssApi}/refresh <- existing endpoint
    // const accessTokenExp = new Date(data.access_token_expiration);
    // const refreshTokenExp = new Date(data.refresh_token_expiration);
    // const now = Date.now().valueOf();
    if (response.status && response.status === 200 && data && data.userId) {
      const role = data.userType?.toLowerCase();
      if (Boolean(data.accessHostApp) && ['admin', 'host', 'referral', 'hostandreferral', 'sales'].includes(role)) {
        // const accessToken = response.headers.get("Authorization");
        const signInData = {
          token: data.access_token,
          expiresIn: 86400,
          tokenType: data.token_type,
          authState: {
            id: data.userId,
            email: data.email,
            name: data.fullName,
            thumbnailUrl: data.avatarUrl,
            role: data.userType,
            accessBookNowApp: data.accessBookNowApp,
          },
          refreshToken: data.refresh_token,
          refreshTokenExpireIn: 1440,
        };

        const signedIn = signIn(signInData);
        if (signedIn) {
          let userHome = ['referral', 'sales'].includes(data.userType) ? ROUTER_URLS.referralProgram.dashboard : ROUTER_URLS.home;
          if (location.state?.from?.pathname && location.state?.from?.pathname !== '/') {
            userHome = location.state?.from?.pathname;
          }
          navigate(userHome);
        }
      } else {
        setErrorText("Invalid username or password")
      }
    } else {
      setErrorText("Invalid username or password")
    }    
  };

  const errorHandler = () => {
    setErrorText('Please check your username and password. If you continue experiencing issues please contact support.')
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get('username') as string | undefined;
    const password = data.get('password') as string | undefined;
    if (username && password) {
      doLogin(username, password, successHandler, errorHandler).catch((e) => {
        console.log(e);
        setErrorText('Please check your username and password. If you continue experiencing issues please contact support.')
      });
    } else {
      setErrorText("Username and password required");
    }
  };

  const handleClickShowPassword = () => { setShowPassword((show) => !show) };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  if (isAuthed) {
    const userHome = ['referral', 'sales'].includes(userInfo?.role) ? ROUTER_URLS.referralProgram.dashboard : ROUTER_URLS.home;
    return (
      <Navigate to={userHome} state={{from: location}} replace/>
    );
  }

  return (
      <Container component="main" maxWidth="xs">
        
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <SquareLogo />
          <Typography component="h1" variant="h5" mt={5}>
            Welcome to {APP_DISPLAY_NAME}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ my: 1 }}>
            { errorText &&
                <Alert severity="error" sx={{ my: 2 }}>{ errorText }</Alert>
            }
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />

            <FormControl fullWidth required variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                name="password"
                autoComplete="current-password"
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' width='100%'>
              <CustomLink to={ROUTER_URLS.user.forgotPassword} text="Forgot Password" />
            </Box>
          </Box>
        </Box>
      </Container>
  );
};

export default LogIn;
