import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Container, Grid, Stack, TextField, Typography } from '@mui/material';


import Dashboard from '../../components/layouts/Dashboard/Dashboard';
// import { DEFAULT_NEW_LISTING_PARAMS, LISTING_CATEGORIES, newListingSteps } from '../../constants/listings';

import { API_V3_ALLIGATOR_URLS } from '../../constants/api-urls';

import { ROUTER_URLS } from '../../constants/router-urls';

import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';

import type { LandingType } from '../../types/landing';
import { useFetchLanding } from '../../hooks/fetchLanding';
import type { IReadFile } from '../../types/readFile';
import { computeChecksumMd5, uploadToS3 } from '../../utils/fileUpload';
import { getCroppedImg } from '../../utils/cropImage';
import FileUploadInput from '../../components/atoms/FileUploadInput/FileUploadInput';
import RoundImageCrop from '../../components/atoms/ImageCrop/ImageCrop';
import { useFetchListings } from '../../hooks/fetchListing';
import SelectableListingCard from '../../components/compounds/ListingCard/SelectableListingCard';
import { listingDisplayName } from '../../utils/listing';

interface IProps extends WrappedProps {};

function EditLanding({ getConfig, postConfig }: IProps) {
  const { id: landingId } = useParams();
  const navigate = useNavigate();

  const [isSaving, setIsSaving] = useState(false);
  const [title, setTitle] = useState('');
  const [selectedListingIds, setSelectedListingIds] = useState<number[]>();

  const [uploadedImages, setUploadedImages] = useState<IReadFile[]>([]);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<any>(null);
  const imagesToUploadCount = useRef(0);

  const { data: landingRecord, isLoading } = useFetchLanding(getConfig, landingId ?? '');
  const { data: listings, isLoading: loadingListings } = useFetchListings(postConfig);
  

  useEffect(() => {
    if (landingRecord) {
      setTitle(landingRecord.title);
      setSelectedListingIds(landingRecord.listings);
    }
    
  }, [landingRecord]);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  }

  const handleBack = () => {
    navigate(ROUTER_URLS.landings.index);
  }

  const readFile = async (file: File) => {
    try {
      return await new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => { resolve(reader.result) }, false)
        reader.readAsDataURL(file)
      })
    } catch (error) {
      console.log(error);
    }
  }

  const saveAfterUpload = async (file: File) => {
    try {
      const imageDataUrl = await readFile(file).catch((e) => { console.log(e) }) as string;
      if (imageDataUrl) {
        const readImage = {
          publicUrl: imageDataUrl,
          ...file,
        }
        setUploadedImages([readImage]);
      }
    } catch(e) {
      console.log(e);
    }
  };

  const getS3Url = async (newFile: File) => {
    const checkSum = await computeChecksumMd5(newFile);
    return await axios.post(
      API_V3_ALLIGATOR_URLS.directUpload.create,
      {
        blob: {
          filename: newFile.name,
          content_type: newFile.type,
          byte_size: newFile.size,
          checksum: checkSum, /* base 64 of the MD5 hash of the file */
        }
      },
      {
        headers: { Authorization: postConfig.headers.Authorization },
      }
    )
  };

  const saveImage = async (): Promise<string | undefined> => {
    try {
      const croppedImage= await getCroppedImg(
        uploadedImages[0].publicUrl ?? '',
        croppedAreaPixels,
      ) as Blob;
      const newImage = new File([croppedImage], 'profilePicture');

      const s3UrlRes = await getS3Url(newImage);

      if (s3UrlRes.data.direct_upload) {
        const directUpload = s3UrlRes.data.direct_upload;

        const uploadToS3Res = await uploadToS3(directUpload.url, directUpload.headers, newImage)
        if (uploadToS3Res.status === 200) {
          return s3UrlRes.data.signed_id;
        }             
      }

    } catch (e) {
      console.error(e)
      return undefined;
    }
  }

  const handleSave = () => {
    setIsSaving(true);
    const params: any = {
      landing_page: {
        title,
        listing_ids: selectedListingIds,
      }
    }

    saveImage().then((signedId) => {
      if (signedId && signedId.length > 0) {
        params.landing_page.logo_image = signedId
      }

      axios.patch(`${API_V3_ALLIGATOR_URLS.landing.update}/${landingRecord?.id ?? ''}`, params, postConfig).then((response) => {
        const newRecord: LandingType = response.data;
        if (newRecord.id) {
          navigate(ROUTER_URLS.landings.index);
        } else {
          console.log('error failed to save');
        }
      }).catch((error) => {
        console.log(error);
      });
    }).catch((e) => {
      console.log(e);
      setIsSaving(false)
    });
  };

  const updateListings = (id: number) => {
    if (!selectedListingIds) return;
    
    const copied = [...selectedListingIds];
    const index = selectedListingIds.indexOf(id);
    if (index < 0) {
      copied.push(id)
    } else {
      copied.splice(index, 1);
    }
    setSelectedListingIds(copied);
  }

  if (!landingRecord) {
    return <Dashboard loading />;
  }

  return (
    <Dashboard loading={isLoading || isSaving || loadingListings}>
      <Container maxWidth='lg'>
        <Stack spacing={5} my={6}>
          <Typography component="p" variant="body1">
            Set the landing page title
          </Typography>
          <TextField
            required
            id="outlined-required"
            label="Title"
            value={title}
            onChange={handleTitleChange}
            fullWidth
          />


          <Box sx={{ my: 3 }}>
            <Typography component="p" variant="body1">
              Add a logo or image
            </Typography>
            {uploadedImages.length === 0 && <FileUploadInput
              promptText='Drag and drop your new landing page image here or'
              buttonText='Upload Photo'
              uploadedFiles={uploadedImages}
              setUploadedFiles={setUploadedImages}
              postConfig={postConfig}
              filesToUploadCount={imagesToUploadCount}
              saveToApi={saveAfterUpload}
            />}
            {uploadedImages.length > 0 &&
              <RoundImageCrop
                uploadedImage={uploadedImages[0]}
                imgSrc={landingRecord.logoImageUrl ?? ''}
                setCroppedAreaPixels={setCroppedAreaPixels}
              />
            }
          </Box>

          <Box sx={{ my: 3 }}>
            <Grid container spacing={5}>
            {
              listings?.map((listing) => {
                const selected = Boolean(selectedListingIds?.includes(Number(listing.id)));

                return (
                  <SelectableListingCard
                    listingId={listing.id}
                    listingThumbnailUrl={listing.thumbnailUrl}
                    listingName={listingDisplayName(listing)}
                    postConfig={postConfig}
                    listingSlug={listing.slug}
                    selected={selected}
                    selectListing={updateListings}
                  />
                );
              })
            }
            </Grid>
          </Box>

          <Box display='flex' flexDirection='row' justifyContent='flex-end'>
            <Button variant="outlined" onClick={handleBack}>Cancel</Button>
            <Button variant="contained" onClick={handleSave} sx={{ mx: 2 }}>Save</Button>
          </Box>
        </Stack>
      </Container>
    </Dashboard>
  );
}

export default withCommonTools(EditLanding);
