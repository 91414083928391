import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { ROUTER_URLS } from '../../constants/router-urls';
import { useFetchListings } from '../../hooks/fetchListing';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import ListingCard from '../../components/compounds/ListingCard/ListingCard';
import Dashboard from '../../components/layouts/Dashboard/Dashboard';
import LoadingSpinner from '../../components/atoms/LoadingSpinner/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { listingDisplayName } from '../../utils/listing';

interface IProps extends WrappedProps {};

function Listings({ postConfig }: IProps) {
  const navigate = useNavigate();

  const { data: listingRecords, isLoading: loading, refetch } = useFetchListings(postConfig);

  const navigateToNewListing = () => {
    navigate(ROUTER_URLS.listings.new);
  };

  return (
    <Dashboard>
      <Grid container paddingY={2} marginTop={5} alignItems='center'>
        <Grid item xs={6}>
          <Typography variant="h5">Listings</Typography>
        </Grid>
        <Grid item xs={6} display='flex' justifyContent='end'>
          <Button
              type="submit"
              variant="contained"
              onClick={navigateToNewListing}
              color='secondary'
            >
              +New Package
          </Button>
        </Grid>
      </Grid>
      { loading ? <LoadingSpinner /> : (
        <Grid container spacing={5}>
          { (listingRecords ?? []).map((listing) => (
              <ListingCard
                key={listing.id}
                listingId={listing.id}
                listingThumbnailUrl={listing.thumbnailUrl}
                listingName={listingDisplayName(listing)}
                listingShortDescription={listing.shortDescription ?? ''}
                listingListingRating={listing.listingRating}
                postConfig={postConfig}
                bookNowSlug={listing.bookNowSlug}
                listingSlug={listing.slug}
                listingPublished={listing.published}
                refetch={refetch}
              />
          ))}
          </Grid>
      ) }
    </Dashboard>
  );
}

export default withCommonTools(Listings);
