import { useState } from "react";
import { Box, Button, CircularProgress, FormControl, MenuItem, Paper, Select, Stack, Typography } from "@mui/material";
import type { SelectChangeEvent } from '@mui/material';
import { useFetchListings } from "../../../hooks/fetchListing";
import { withCommonTools } from '../CommonWrapper/withCommonTools';
import type { WrappedProps } from '../CommonWrapper/withCommonTools';
import { useUserCanAccessBookNow } from '../../../hooks/userCanAccess';
import SendIcon from '@mui/icons-material/Send';
import { listingDisplayName } from "../../../utils/listing";

interface IProps extends WrappedProps {};

function QuickShareCard({ postConfig }: IProps) {
  const canAccessBookNow = useUserCanAccessBookNow();
  const { data: listingRecords, isLoading } = useFetchListings(postConfig);

  const [selectedListingId, setSelectedListingId] = useState<string>('');
  const [loadingShare, setLoadingShare] = useState(false);

  const shareUrl = () => {
    setLoadingShare(true);
    const listing = listingRecords?.find((listing) => { return listing.id.toString() === selectedListingId });
    if (listing) {
      const previewLink = canAccessBookNow ? `https://www.huntpay.com/listing/s/${listing.bookNowSlug}` : `https://www.venku.com/listing/s/${listing.slug}`
      const title = canAccessBookNow ? 'HuntPay' : 'Venku';
      const text = canAccessBookNow ? 'Reserve your hunt with HuntPay' : 'Book your hunt with Venku';
      const shareData = {
        title,
        text,
        url: previewLink,
      };
      if (navigator.canShare(shareData)) {
        navigator.share(shareData).then(() => {
          setSelectedListingId('')
          setLoadingShare(false);
        }).catch((e) => {
          console.log(e)
          setLoadingShare(false);
        });
      }
    } else {
      setLoadingShare(false);
    }
  }

  const handleListingSelectChange = (event: SelectChangeEvent) => {
    setSelectedListingId(event.target.value);
  };

  return (
    <Paper elevation={2} sx={{ borderRadius: 3, height: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', borderBottom: 1, borderColor: 'divider', width: '100%',  }}>
        <Typography
          variant='h6'
          component='h6'
          align='center'
          sx={{
            mt: 2,
            mb: 2,
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            width: '75%',
            alignItems: 'center',
            fontWeight: 600,
          }}
        >
          Quick Share
        </Typography>
      </Box>        
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        justifyItems: 'center',
        borderColor: 'divider',
        flexGrow: 1,
        width: '100%',
      }}>
        <Stack spacing={2} sx={{ display: 'flex', flexGrow: 1, margin: 3, justifyContent: 'center', justifyItems: 'center', alignItems: 'center', }}>
        { isLoading ?
          <Box sx={{
            mt: 3,
            mb: 3,
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            alignItems: 'center',
            minHeight: '72px',
          }}>
            <CircularProgress />
          </Box> :
          <>
            <FormControl fullWidth>
              <Select
                labelId="listing-label"
                id="listing-select"
                value={selectedListingId}
                onChange={handleListingSelectChange}
                fullWidth
                placeholder='Select Package'
                displayEmpty
              >
                <MenuItem value="">
                  Select Package
                </MenuItem>
                {listingRecords && listingRecords.length > 0 && listingRecords.map((listing) => {
                    return (
                      <MenuItem key={listing.id} value={listing.id.toString()}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                          <Box
                            onClick={(e) => {
                              e.stopPropagation();
                              console.log('clicked!')
                            }}
                            sx={{ backgroundColor: listing.hexColor, height: '20px', width: '20px', borderRadius: 1 }}
                          /><Typography>{listingDisplayName(listing)}</Typography>
                        </Box>
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
            <Button disabled={selectedListingId === '' || loadingShare} variant='contained' onClick={shareUrl} sx={{ minWidth: { xs: '100px', md: '200px' } }} endIcon={<SendIcon />}>
              Share Link
            </Button>
          </>
        }
        </Stack>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          justifyItems: 'center',
          width: '100%',
        }}>
          <Typography variant="body2" sx={{ paddingTop: 1, textAlign: 'center', fontStyle: 'italic', paddingX: 2, marginBottom: 1, }}>
            * Quickly share a package with customers from your contacts via text or email
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
}

export default withCommonTools(QuickShareCard);