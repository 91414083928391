import React from 'react';
import { Grid, Typography } from '@mui/material';
import PaperWithHeader from '../PaperWithHeader/PaperWithHeader';
import type { BookingDetailType } from '../../../types/booking';
import { formatDateRange } from '../../../utils/dates';
import { camelToProper } from '../../../utils/strings';
import { listingDisplayName } from '../../../utils/listing';

interface IProps {
  booking: BookingDetailType
}

const dateFormat = 'MMM d, yyyy';

function BookingListingCard({ booking }: IProps) {
  const bookingStatusStyles = booking.status.toLowerCase() === 'paymentfailed' ? { color: '#d32f2f', fontWeight: 600 } : {};

  return (
    <PaperWithHeader headerTitle='Summary'>
      <Grid container direction='row' padding={2} spacing={2}>
        <Grid item xs={12} lg={12}>
          <Grid container marginY={2}>
            <Grid item xs={4}>
              <Typography variant="body1" sx={{ fontWeight: 600, marginRight: 1 }}>Package Name:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" sx={{ width: '100%' }}>{ listingDisplayName(booking.listing) }</Typography>
            </Grid>
          </Grid>
          <Grid container marginY={2}>
            <Grid item xs={4}>
              <Typography variant="body1" sx={{ fontWeight: 600, marginRight: 1, width: 120 }}>Days:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{ formatDateRange(booking.startDate, booking.endDate, dateFormat)}</Typography>
            </Grid>
          </Grid>
          <Grid container marginY={2}>
            <Grid item xs={4}>
              <Typography variant="body1" sx={{ fontWeight: 600, marginRight: 1, width: 120 }}>Status:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" sx={bookingStatusStyles}>{ camelToProper(booking.status) }</Typography>
            </Grid>
          </Grid>
          <Grid container marginY={2}>
            <Grid item xs={4}>
              <Typography variant="body1" sx={{ fontWeight: 600, marginRight: 1, width: 120 }}>Address:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1"></Typography>
            </Grid>
          </Grid>
          <Grid container marginY={2}>
            <Grid item xs={4}>
              <Typography variant="body1" sx={{ fontWeight: 600, marginRight: 1, width: 120 }}>Lat/Long:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{ booking.address?.latitude }, { booking.address?.longitude }</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PaperWithHeader>
  );
}

export default BookingListingCard;