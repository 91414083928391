import { Box, Button, Container, Typography } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { API_V3_ALLIGATOR_URLS } from "../../constants/api-urls";
import { APP_DISPLAY_NAME } from "../../constants/app-name";

function ConfirmEmail() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('confirmation_token');

  useEffect(() => {
    if (token && token.length > 0) {
      axios.post(API_V3_ALLIGATOR_URLS.auth.confirmEmail, { confirmation_token: token }).then((res) => {
        console.log(res.data);
      }).catch((e) => {
        console.log(e);
      })
    }
  }, [token])

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ height: 400, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} className='unstyled-link'>
        <Typography textAlign='center' sx={{ maxWidth: 600 }}>
          Welcome to {APP_DISPLAY_NAME}! Please use your email and password to login on the next page.
        </Typography>
        <a href='/login'>
          <Button variant='contained' sx={{ textTransform: 'none', width: 100, marginY: 2 }}>
            Sign in
          </Button>
        </a>
      </Box>
    </Container>
  )
}

export default ConfirmEmail;