import React, { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import DashboardLayout from '../../../components/layouts/Dashboard/Dashboard';
import { LISTING_CATEGORIES } from '../../../constants/listings';
import type { ListingCategoryType } from '../../../types/listing';
import AddableAutocomplete from '../../../components/atoms/AddableAutocomplete/AddableAutocomplete';
import { ROUTER_URLS } from '../../../constants/router-urls';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';
import { withCommonTools } from '../../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../../components/compounds/CommonWrapper/withCommonTools';
import HostAutoSelect from '../../../components/compounds/HostAutoSelect/HostAutoSelect';
import type { UserResultType } from '../../../types/user';
import { LISTING_COLOR_PALLETE } from '../../../constants/listing-colors';

interface IProps extends WrappedProps {};

export const removeInvalidOptions = (simpleArray: Array<string | undefined>): string[] => (
  simpleArray.filter((value) => Boolean(value) && value !== ',') as string[]
);

function AdminNewListing({ postConfig }: IProps) {
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [nickname, setNickname] = useState('');
  const [category, setCategory] = useState('');
  const [keywords, setKeywords] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const [host, setHost] = useState<UserResultType | null>(null);

  const handleSave = () => {
    setIsSaving(true);
    const randomIndex = Math.floor(Math.random() * LISTING_COLOR_PALLETE.length);
    const randomValue = LISTING_COLOR_PALLETE[randomIndex];
    const params = {
      listing: {
        name: title,
        host_app_display_name: nickname,
        category,
        keywords,
        host_id: host?.id,
        hex_color: randomValue
      }
    };

    axios.post(API_V3_ALLIGATOR_URLS.listing.create, params, postConfig).then((response) => {
      const id = response.data.id;
      const path = generatePath(ROUTER_URLS.admin.listings.show, { id });
      navigate(path);
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setIsSaving(false);
    });
  };

  const handleCancel = () => {
    navigate(ROUTER_URLS.admin.listings.index);
  }

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  }

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
  };

  const handleNicknameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNickname(event.target.value);
  }

  return (
    <DashboardLayout loading={isSaving}>
      <Grid container direction='column' spacing={3} marginTop={3} marginBottom={10}>
        <Grid item xs={12} lg={6}>
          <Typography variant='h5'>New Package</Typography>
        </Grid>
        <Grid item xs={12}>
          <HostAutoSelect value={host} setValue={setHost} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            required
            id="outlined-required"
            label="Title"
            value={title}
            onChange={handleTitleChange}
            fullWidth
            helperText="Package title will be present on search"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            id="outlined-required"
            label="Host app nickname"
            value={nickname}
            onChange={handleNicknameChange}
            fullWidth
            helperText="Change how the listing name appears for the hosts"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-helper-label" required>Category</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={category}
              label="Category"
              onChange={handleCategoryChange}
              required
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              { LISTING_CATEGORIES.map((option: ListingCategoryType) => (
                <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
              ))}
              
            </Select>
            <FormHelperText>Pick a category for your package</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={12}>
          <AddableAutocomplete
            value={keywords}
            setValue={setKeywords}
            defaultOptions={[]}
            label="Keywords"
            helperText="Add tags to your package by typing and selecting them"
            required={false}
          />
        </Grid>
        <Grid item sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            color='primary'
            variant='outlined'
            onClick={handleCancel}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button onClick={handleSave} variant='contained' color='primary'>
            Save
          </Button>
        </Grid>
      </Grid>      
    </DashboardLayout>
  );
}

export default withCommonTools(AdminNewListing);
