import React, { useState } from 'react';
import { formatDateString } from '../../../../utils/dates';
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { arrayToExcel } from '../../../../utils/arrayToExcel';
import ExcelIcon from '../../../atoms/ExcelIcon/ExcelIcon';

interface IProps {
  data: Array<{
    date: string;
    payee: string;
    partyCount: number;
    amount: number;
    listingName: string;
    paymentType: string;
  }>;
}

export default function Orders({ data }: IProps) {
  const [limit, setLimit] = useState(15);

  return (
    <React.Fragment>
      <Box display='flex' flexDirection='row' flexGrow={1} justifyContent='space-between' marginBottom={2}>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          Deposits - Last 12 Months
        </Typography>
        <Button onClick={() => { arrayToExcel(data) }} sx={{ display: { xs: 'block', md: 'none' } }} startIcon={<ExcelIcon />} />
        <Button variant='outlined' onClick={() => { arrayToExcel(data) }} sx={{ display: { xs: 'none', md: 'flex' } }} startIcon={<ExcelIcon />}>
          Download
        </Button>
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 600 }}>Date</TableCell>
            <TableCell sx={{ fontWeight: 600 }}>Payee</TableCell>
            <TableCell sx={{ fontWeight: 600, display: { xs: 'none', md: 'table-cell' }} }>Party Size</TableCell>
            <TableCell sx={{ fontWeight: 600, display: { xs: 'none', md: 'table-cell' }} }>Package Name</TableCell>
            <TableCell sx={{ fontWeight: 600, display: { xs: 'none', md: 'table-cell' }} }>Type</TableCell>
            <TableCell sx={{ fontWeight: 600 }} align="right">Deposit Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.slice(0, limit).map((row) => (
            <TableRow key={`${row.date}-${row.payee}`}>
              <TableCell>{formatDateString(row.date)}</TableCell>
              <TableCell>{row.payee}</TableCell>
              <TableCell sx={{ display: { xs: 'none', md: 'table-cell' }}}>{row.partyCount}</TableCell>
              <TableCell sx={{ display: { xs: 'none', md: 'table-cell' }}}>{row.listingName}</TableCell>
              <TableCell sx={{ display: { xs: 'none', md: 'table-cell' }}}>{row.paymentType}</TableCell>
              <TableCell align="right">{`$${(row.amount ?? 0).toFixed(2)}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      { data.length > limit &&
        <Button sx={{ mt: 3 }} onClick={() => { setLimit(limit + 25) }}>
          See more deposits
        </Button>
      }
    </React.Fragment>
  );
}